import React from 'react';

import { withRouter } from '../../components/withRouter/withRouter.tsx';
import LogoLeftColumn from '../../components/logoLeftColumn/LogoLeftColumn.js';

import '../../styles/offerings.css';
import './OfferCompletePage.css';

class OfferCompletePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          windowWidth: window.innerWidth,
          router: props.router
        };
        this.updateWindowWidth = this.updateWindowWidth.bind(this);
    }

    componentDidMount() {
        this.updateWindowWidth();
        window.addEventListener('resize', this.updateWindowWidth);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowWidth);
    }

    updateWindowWidth() {
        this.setState({ windowWidth: window.innerWidth });
    }
    
    render() {
        let { windowWidth } = this.state;
        const isMobile = (windowWidth < 768);
        return(
            <div className="offer-page-container offer-complete-page row">
                {(!isMobile) && (
                    <LogoLeftColumn link="/" mediaDisplay={false}/>
                )}
                <div className={isMobile ? "offer-centre" : "offer-centre col-8"}>
                    <h1 className="nw-heading offer-heading">Thank you</h1>
                    <div className="offer-section">
                        <p className="nw-title">
                            Your offer of emergency accommodation has been recorded. 
                        </p>
                        <p className="nw-title">
                            You will be contact if this accommodation is needed. 
                        </p>
                    </div>
                </div>
                {(!isMobile) && (
                    <div className="offer-right col-2"></div>
                )}
            </div>
        );
    }
}

export default withRouter(OfferCompletePage);