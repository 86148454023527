export const USER_KEY = "nw-user";
export const SURVEY_KEY = "nw-survey";
export const OWNER_KEY = "nw-owner";
export const ACCOMMODATION_KEY = "nw-accommodation";
export const ACCOM_AMENITIES_KEY = "nw-accom-amenities";
export const ROOMS_KEY = "nw-rooms";
export const ACCOM_APPLICANT_KEY = "nw-accom-applicant";
export const ACCOM_APPLICATION_KEY = "nw-accom-application";
export const ACCOM_PREFERENCES_KEY = "nw-accom-preferences";
export const APPLICANT_CONTACTS_KEY = "nw-applicant-contacts";
export const EMERGENCY_CONTACTS_KEY = "nw-emergency-contacts";