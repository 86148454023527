import { createContext } from 'react';

const GlobalContext = createContext({
    user: {},
    survey: {},
    owner: {},
    accommodation: {},
    accomAmenities: [],
    rooms: [],
    accomApplicant: {},
    accomApplication: {},
    accomPreferences: [],
    applicantContacts: [],
    emergencyContacts: [],
    setUserValues: () => {},
    setSurveyValues: () => {},
    setOwnerValues: () => {},
    setAccommodationValues: () => {},
    setAccomAmenities: () => {},
    setRooms: () => {},
    setAccomApplicantValues: () => {},
    setAccomApplicationValues: () => {},
    setAccomPreferences: () => {},
    setApplicantContacts: () => {},
    setEmergencyContacts: () => {}
});
export default GlobalContext;