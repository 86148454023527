export const Roles = {
    USER: {
        id: 1,
        name: "user"
    },
    ADMIN: {
        id: 2,
        name: "admin"
    },
    OWNER: {
        id: 3,
        name: "owner"
    },
    APPLICANT: {
        id: 4,
        name: "applicant"
    },
    MANAGER: {
        id: 5,
        name: "manager"
    },
    ORGANISATION: {
        id: 6,
        name: "organisation"
    },
    AGENT: {
        id: 7,
        name: "agent"
    },
};