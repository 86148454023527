import axios from 'axios';

import * as ServerConstants from '../constants/ServerConstants';

let DataService = {};

DataService.fullDataLoad = () => {
    return axios.get(ServerConstants.API_URL + '/fulldata', 
        { withCredentials: true }
    );
};

const fullDataSave = (data) => {
    return axios.post(ServerConstants.API_URL + '/fulldata',
        { data }, 
        { withCredentials: true }
    );
};

DataService.fullDataSave = fullDataSave;

DataService.saveOfferData = (data) => {
    let dataToSave = {};
    if (data.owner) {
        let owner = JSON.parse(JSON.stringify(data.owner));
        if (!owner.hasOwnProperty("id")) {
            owner.id = 0;
        }
        dataToSave.owner = owner;
    }
    if (data.accommodation) {
        let accommodation = JSON.parse(JSON.stringify(data.accommodation));
        if (!accommodation.hasOwnProperty("id")) {
            accommodation.id = 0;
        }
        dataToSave.accommodation = accommodation;
    }
    if (data.accomAmenities) {
        let accomAmenities = [];
        data.accomAmenities.forEach(aa => {
            let accomAmenity = JSON.parse(JSON.stringify(aa));
            if (!accomAmenity.hasOwnProperty("id")) {
                accomAmenity.id = 0;
            }
            accomAmenities.push(accomAmenity);
        });
        dataToSave.accomAmenities = accomAmenities;
    }
    if (data.rooms) {
        let rooms = [];
        data.rooms.forEach(rm => {
            let room =JSON.parse(JSON.stringify(rm));
            if (!room.hasOwnProperty("id")) {
                room.id = 0;
            }
            rooms.push(room);
        });
        dataToSave.rooms = rooms;
    }
    return fullDataSave(dataToSave);
}

DataService.saveApplicationData = (data) => {
    let dataToSave = {};
    // TODO: saveApplicationData
    if (data.accomApplicant) {
        let accomApplicant = JSON.parse(JSON.stringify(data.accomApplicant));
        if (!accomApplicant.hasOwnProperty("id")) {
            accomApplicant.id = 0;
        }
        dataToSave.accomApplicant = accomApplicant;
    }
    if (data.accomApplication) {
        let accomApplication = JSON.parse(JSON.stringify(data.accomApplication));
        if (!accomApplication.hasOwnProperty("id")) {
            accomApplication.id = 0;
        }
        dataToSave.accomApplication = accomApplication;
    }
    if (data.accomPreferences) {
        let accomPreferences = [];
        data.accomPreferences.forEach(ap => {
            let accomPreference =JSON.parse(JSON.stringify(ap));
            if (!accomPreference.hasOwnProperty("id")) {
                accomPreference.id = 0;
            }
            accomPreferences.push(accomPreference);
        });
        dataToSave.accomPreferences = accomPreferences
    }
    if (data.applicantContacts) {
        let applicantContacts = [];
        data.applicantContacts.forEach(ac => {
            let applicantContact =JSON.parse(JSON.stringify(ac));
            if (!applicantContact.hasOwnProperty("id")) {
                applicantContact.id = 0;
            }
            applicantContacts.push(applicantContact);
        });
        dataToSave.applicantContacts = applicantContacts
    }
    if (data.emergencyContacts) {
        let emergencyContacts = [];
        data.emergencyContacts.forEach(ec => {
            let emergencyContact =JSON.parse(JSON.stringify(ec));
            if (!emergencyContact.hasOwnProperty("id")) {
                emergencyContact.id = 0;
            }
            emergencyContacts.push(emergencyContact);
        });
        dataToSave.emergencyContacts = emergencyContacts
    }
    return fullDataSave(dataToSave);
};

DataService.createKeyValues = (data) => {
    let kvdata = [];
    for (const [key, value] of Object.entries(data)) {
        kvdata.push({ key: key, value: value });
    }
    return kvdata;
};

export default DataService;