export const CONTACT_TYPES = [
    { 
        label: "Phone / Mobile", 
        value: "phone", 
        class: "offer-contact-left", 
        buttonClass: "offer-rounded-button-left",
        contactFieldTitle: "Enter your phone / mobile:"
    },
    { 
        label: "Email", 
        value: "email", 
        class: "offer-contact-centre", 
        buttonClass: "offer-rounded-button",
        contactFieldTitle: "Enter your email account:" 
    },
    { 
        label: "Facebook", 
        value: "facebook", 
        class: "offer-contact-right", 
        buttonClass: "offer-rounded-button",
        contactFieldTitle: "Enter your Facebook account:" 
    },
    { 
        label: "LinkedIn", 
        value: "linked", 
        class: "offer-contact-left", 
        buttonClass: "offer-rounded-button-left",
        contactFieldTitle: "Enter your LinkedIn account:" 
    },
    { 
        label: "Twitter", 
        value: "twitter", 
        class: "offer-contact-centre", 
        buttonClass: "offer-rounded-button",
        contactFieldTitle: "Enter your Twitter account:" 
    },
    { 
        label: "Instagram", 
        value: "instagram", 
        class: "offer-contact-right", 
        buttonClass: "offer-rounded-button",
        contactFieldTitle: "Enter your Instagram account:" 
    }
];

export const PAYMENT_CONDITIONS = [
    { label: "I would need to be paid", value: "paid" },
    { label: "I would offer it for free", value: "free" },
    { label: "It would depend on my situation", value: "conditional" }
];

export const ACCOMMODATION_TYPES = [
    { label: "Entire Property", value: "entire property" },
    { label: "Private Room/s", value: "private rooms" },
    { label: "Granny Flat", value: "granny flat" },
    { label: "Caravan", value: "caravan" },
    { label: "Camping Area (with Power & Amenities)", value: "powered camping" },
    { label: "Camping Area (without Power)", value: "unpowered camping" },
    { label: "Shed or Garage", value: "shed" },
    { label: "Other", value: "other" }
];

export const PRIOR_LISTING = [
    { label: "Yes", value: "yes" },
    { label: "Yes, but handled by a property manager", value: "yes - managed" },
    { label: "No", value: "no" }
];

export const ROOM_COUNT_VALUES = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3+", value: "3+" },
    { label: "None", value: "0" }
];

export const ENTRY_STAIRS = [
    { label: "No", value: "no" },
    { label: "Yes, only 1", value: "1" },
    { label: "Yes, up to 5", value: "<= 5" },
    { label: "Yes, more than 5", value: "> 5" },
];

export const FLOORING_TYPES = [
    { label: "Thin carpet", value: "thin carpet" },
    { label: "Thick carpet", value: "thick carpet" },
    { label: "Hardwood floor", value: "hardwood floor" }
];

export const GROUND_COVERINGS = [
    { label: "", value: "" },
    { label: "Cobblestone", value: "cobblestone" },
    { label: "Dirt", value: "dirt" },
    { label: "Grass", value: "grass" },
    { label: "Sand", value: "sand" },
    { label: "Gravel", value: "gravel" },
    { label: "Deck", value: "deck" },
    { label: "Outdoor Tiles", value: "tiles" },
    { label: "Concrete", value: "concrete" }
];

export const PARKING_TYPES = [
    { label: "Yes", value: "yes" },
    { label: "Yes, but it requires stairs to access", value: "yes but stairs" },
    { label: "No", value: "no" },
];

export const RAILING_LOCATIONS = [
    { 
        label: "Bedrooms", 
        value: "bedrooms", 
        class: "col-4 offer-amenity-button-wrapper", 
        buttonClass: "offer-rounded-button-bm", 
        tip: "",
        field: "bedroomRailing" 
    },
    { 
        label: "Showers", 
        value: "showers", 
        class: "col-4 offer-amenity-button-wrapper", 
        buttonClass: "offer-rounded-button-bm", 
        tip: "" ,
        field: "showerRailing"
    },
    { 
        label: "Next to Toilets", 
        value: "next to toilets", 
        class: "col-4 offer-amenity-button-wrapper", 
        buttonClass: "offer-rounded-button-bm", 
        tip: "",
        field: "toiletRailing" 
    },
    { 
        label: "Hallways", 
        value: "hallways", 
        class: "col-4 offer-amenity-button-wrapper", 
        buttonClass: "offer-rounded-button-bm", 
        tip: "",
        field: "hallwayRailing" 
    },
    { 
        label: "Kitchen", 
        value: "kitchen", 
        class: "col-4 offer-amenity-button-wrapper", 
        buttonClass: "offer-rounded-button-bm", 
        tip: "",
        field: "kitchenRailing" 
    },
    { 
        label: "Living Room", 
        value: "living-room", 
        class: "col-4 offer-amenity-button-wrapper", 
        buttonClass: "offer-rounded-button-bm", 
        tip: "",
        field: "livingRailing" 
    },
    { 
        label: "Outside (leading to entrance)", 
        value: "outside", 
        class: "col-8 offer-amenity-button-wrapper", 
        buttonClass: "offer-rounded-button-bm", 
        tip: "",
        field: "externalRailing" 
    }
];

export const BEDROOM_AMENITIES = [
    { 
        label: "Bed Linen", 
        value: "bed linen", 
        class: "col-4 offer-amenity-button-wrapper", 
        buttonClass: "offer-rounded-button-bm", 
        tip: "" 
    },
    { 
        label: "Wardrobe Space", 
        value: "wardrobe space", 
        class: "col-4 offer-amenity-button-wrapper", 
        buttonClass: "offer-rounded-button-bm", 
        tip: "" 
    },
    { 
        label: "Cots", 
        value: "cots", 
        class: "col-4 offer-amenity-button-wrapper", 
        buttonClass: "offer-rounded-button-bm", 
        tip: "" 
    },
    { 
        label: "Additional Sleeping Arrangements", 
        value: "additional sleeping", 
        class: "col-8 offer-amenity-button-wrapper", 
        buttonClass: "offer-rounded-button-bm", 
        tip: "" 
    }
];

export const BATHROOM_AMENITIES = [
    { 
        label: "Hot Water", 
        value: "hot water", 
        class: "col-4 offer-amenity-button-wrapper", 
        buttonClass: "offer-rounded-button-bm", 
        tip: "" 
    },
    { 
        label: "Soap / Shampoo / Conditioner", 
        value: "soap / shampoo", 
        class: "col-8 offer-amenity-button-wrapper", 
        buttonClass: "offer-rounded-button-bm", 
        tip: "" 
    },
    { 
        label: "Towels", 
        value: "towels", 
        class: "col-4 offer-amenity-button-wrapper", 
        buttonClass: "offer-rounded-button-bm", 
        tip: "" 
    },
    { 
        label: "Toothbrush / Toothpaste", 
        value: "toothbrush / toothpaste", 
        class: "col-8 offer-amenity-button-wrapper", 
        buttonClass: "offer-rounded-button-bm", 
        tip: "" 
    }
];

export const KITCHEN_AMENITIES = [
    { 
        label: "Private", 
        value: "private kitchen", 
        class: "col-4 offer-amenity-button-wrapper", 
        buttonClass: "offer-rounded-button-bm", 
        tip: "" 
    },
    { 
        label: "Oven", 
        value: "oven", 
        class: "col-4 offer-amenity-button-wrapper", 
        buttonClass: "offer-rounded-button-bm", 
        tip: "" 
    },
    { 
        label: "Microwave", 
        value: "microwave", 
        class: "col-4 offer-amenity-button-wrapper", 
        buttonClass: "offer-rounded-button-bm", 
        tip: "" 
    },
    { 
        label: "Fridge", 
        value: "fridge", 
        class: "col-4 offer-amenity-button-wrapper", 
        buttonClass: "offer-rounded-button-bm", 
        tip: "" 
    },
    { 
        label: "Tea / Coffee Maker", 
        value: "tea / coffee maker", 
        class: "col-4 offer-amenity-button-wrapper", 
        buttonClass: "offer-rounded-button-bm", 
        tip: "" 
    },
    { 
        label: "Utensils", 
        value: "utensils", 
        class: "col-4 offer-amenity-button-wrapper", 
        buttonClass: "offer-rounded-button-bm", 
        tip: "" 
    },
    { 
        label: "Cutlery", 
        value: "cutlery", 
        class: "col-4 offer-amenity-button-wrapper", 
        buttonClass: "offer-rounded-button-bm", 
        tip: "" 
    },
    { 
        label: "Cleaning Products", 
        value: "kitchen cleaning products", 
        class: "col-4 offer-amenity-button-wrapper", 
        buttonClass: "offer-rounded-button-bm", 
        tip: "" 
    }
];

export const LAUNDRY_AMENITIES = [
    { 
        label: "Private", 
        value: "private laundry", 
        class: "col-4 offer-amenity-button-wrapper", 
        buttonClass: "offer-rounded-button-bm", 
        tip: "" 
    },
    { 
        label: "Washing Machine", 
        value: "washing machine", 
        class: "col-4 offer-amenity-button-wrapper", 
        buttonClass: "offer-rounded-button-bm", 
        tip: "" 
    },
    { 
        label: "Dryer", 
        value: "dryer", 
        class: "col-4 offer-amenity-button-wrapper", 
        buttonClass: "offer-rounded-button-bm", 
        tip: "" 
    },
    { 
        label: "Cleaning Products", 
        value: "laundry cleaning products", 
        class: "col-4 offer-amenity-button-wrapper", 
        buttonClass: "offer-rounded-button-bm", 
        tip: "" 
    }
];
