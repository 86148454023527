import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { withRouter } from '../../components/withRouter/withRouter.tsx';
import LogoLeftColumn from '../../components/logoLeftColumn/LogoLeftColumn.js';
import CustomPagination from '../../components/customPagination/CustomPagination';
import GlobalContext from '../../components/context/GlobalContext';
import * as AccApplicationConstants from '../../constants/AccApplicationConstants.js';

import '../../styles/applications.css';
import './AccAppNumbersNeedsPage.css';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';

class AccAppNumbersNeedsPage extends React.Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    this.state = {
      pagination: [
        { text: "1", link: "/accapp/1", alert: "", cbflag: true, active: true }, 
        { text: "2", link: "/accapp/2", alert: "", cbflag: true, active: true }, 
        { text: "3", link: "/accapp/3", alert: "", cbflag: true, active: true },
        { text: "4", link: "/accapp/4", alert: "", cbflag: true, active: true }
      ],
      current: "3",
      windowWidth: window.innerWidth,
      router: props.router
    };
    this.updateWindowWidth = this.updateWindowWidth.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  componentDidMount() {
    this.updateWindowWidth();
    window.addEventListener('resize', this.updateWindowWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }

  updateWindowWidth() {
    this.setState({ windowWidth: window.innerWidth });
  }

  handleNextClick() {
    this.state.router.navigate("/accapp/4");
  }

  handlePageClick(link, alertText) {
    this.checkPageValidation((valid, msg) => {
      if (valid) {
        if (alertText !== "") {
          alert(alertText);
        } else {
          this.state.router.navigate(link);
        }
      } else {
        this.setState({
          alertMessage: msg
        });
      }
    });
  }

  checkPageValidation(callback) {
    /* TODO: Add functionality to handle validation */
    callback(true, "");
  }

  render() {
    let { windowWidth, pagination, current } = this.state;
    const isMobile = (windowWidth < 768);
    return (
      <div className="accapp-page-container app-numbers-needs-page row">
        {(!isMobile) && (
          <LogoLeftColumn link="/" mediaDisplay={false}/>
        )}
        <div className={isMobile ? "accapp-centre" : "accapp-centre col-8"}>
          <CustomPagination pages={pagination} current={current} callback={(l,a) => this.handlePageClick(l,a)}/>
          <h1 className="nw-heading">Tell Us More About What You Need</h1>
          <div className="accapp-section">
            <h2 className="nw-title">Who needs to be accommodated?</h2>
            <div className="accapp-ppl-numbers-wrapper">
              <label htmlFor="noOfAdults" className="accapp-ppl-numbers-label nw-inline-title">
                Number of adults
              </label>
              <Form.Control
                id="noOfAdults" 
                type="text"
                className="nw-input accapp-ppl-numbers-input"
                value={this.context.accomApplication.noOfAdults}
                onChange={(e) => {
                  this.context.setAccomApplicationValues([{ key: "noOfAdults", value: e.target.value }]);
                }}
              />
            </div>
            <div className="accapp-ppl-numbers-wrapper">
              <label htmlFor="noOfOlderChildren" className="accapp-ppl-numbers-label nw-inline-title">
                Number of older children (13 - 17 yrs)
              </label>
              <Form.Control
                id="noOfOlderChildren" 
                type="text"
                className="nw-input accapp-ppl-numbers-input"
                value={this.context.accomApplication.noOfOlderChildren}
                onChange={(e) => {
                  this.context.setAccomApplicationValues([{ key: "noOfOlderChildren", value: e.target.value }]);
                }}
              />
            </div>
            <div className="accapp-ppl-numbers-wrapper">
              <label htmlFor="noOfChildren" className="accapp-ppl-numbers-label nw-inline-title">
                Number of children (2 - 12 yrs)
              </label>
              <Form.Control
                id="noOfChildren" 
                type="text"
                className="nw-input accapp-ppl-numbers-input"
                value={this.context.accomApplication.noOfChildren}
                onChange={(e) => {
                  this.context.setAccomApplicationValues([{ key: "noOfChildren", value: e.target.value }]);
                }}
              />
            </div>
            <div className="accapp-ppl-numbers-wrapper">
              <label htmlFor="noOfInfants" className="accapp-ppl-numbers-label nw-inline-title">
                Number of infants (under 2 yrs)
              </label>
              <Form.Control
                id="noOfInfants" 
                type="text"
                className="nw-input accapp-ppl-numbers-input"
                value={this.context.accomApplication.noOfInfants}
                onChange={(e) => {
                  this.context.setAccomApplicationValues([{ key: "noOfInfants", value: e.target.value }]);
                }}
              />
            </div>
            <div className="accapp-ppl-numbers-wrapper">
              <label htmlFor="noOfPets" className="accapp-ppl-numbers-label nw-inline-title">
                Number of pets
              </label>
              <Form.Control
                id="noOfPets" 
                type="text"
                className="nw-input accapp-ppl-numbers-input"
                value={this.context.accomApplication.noOfPets}
                onChange={(e) => {
                  this.context.setAccomApplicationValues([{ key: "noOfPets", value: e.target.value }]);
                }}
              />
            </div>
            <div className="accapp-ppl-other-wrapper">
              <label htmlFor="applicantTypeOther" className="accapp-ppl-other-label nw-inline-title">
                Other:
              </label>
              <Form.Control
                id="applicantTypeOther" 
                type="text"
                className="nw-input accapp-ppl-numbers-input"
                value={this.context.accomApplication.applicantTypeOther}
                onChange={(e) => {
                  this.context.setAccomApplicationValues([{ key: "applicantTypeOther", value: e.target.value }]);
                }}
              />
            </div>
          </div>
          <div className="accapp-section">
            <h2 className="nw-title">Are there any vulnerabilities or special needs that need to be considered in the search for accommodation?</h2>
            {AccApplicationConstants.SPECIAL_NEEDS.map((need, index) => (
              <ButtonGroup key={`accapp-needs-grp-${index}`} className="accapp-needs-button-group" aria-label={need.label}>
                <ToggleButton
                  key={`accapp-needs-${index}`}
                  className="nw-toggle-button accapp-needs-button"
                  variant="outline-dark"
                  type="radio"
                  checked={this.context.accomApplication[need.field] === true}
                  onChange={(e) => {
                    this.context.setAccomApplicationValues([{ key: need.field, value: JSON.parse(e.target.value) }]);
                  }}
                >
                  {need.label}
                </ToggleButton>
              </ButtonGroup>
            ))}
            <ButtonGroup className="accapp-needs-button-group" aria-label="Special needs - other">
              <ToggleButton
                className="nw-toggle-button accapp-needs-button-other"
                variant="outline-dark"
                type="radio"
                checked={this.context.accomApplication.vulnerabilitiesOther.length > 0}
                onChange={(e) => {
                  if (JSON.parse(e.target.value) === false) {
                    this.context.setAccomApplicationValues([{ key: "vulnerabilitiesOther", value: "" }]);
                  }
                }}
              >
                Other
              </ToggleButton>
            </ButtonGroup>
            <Form.Control 
              as="textarea"
              rows={3}
              className="nw-input"
              aria-label="Enter other special needs"
              value={this.context.accomApplication.vulnerabilitiesOther}
              onChange={(e) => {
                this.context.setAccomApplicationValues([{ key: "vulnerabilitiesOther", value: e.target.value }]);
              }}
            />
          </div>
          <div className="accapp-section">
            <h2 className="nw-title">Do any of the following apply to you?</h2>
            {AccApplicationConstants.CLIENT_TYPES.map((client, index) => (
              <ButtonGroup key={`accapp-client-grp-${index}`} className="accapp-client-button-group" aria-label={client.label}>
                <ToggleButton
                  className="nw-toggle-button accapp-client-button"
                  variant="outline-dark"
                  type="radio"
                  checked={this.context.accomApplication[client.field] === true}
                  onChange={(e) => {
                    this.context.setAccomApplicationValues([{ key: client.field, value: JSON.parse(e.target.value) }]);
                  }}
                >
                  {client.label}
                </ToggleButton>
              </ButtonGroup>
            ))}
          </div>

          <Button 
            variant="outline-dark" 
            className="nw-submit-button accapp-bottom-button" 
            aria-label="Go to next page"
            onClick={this.handleNextClick}
          >
            Next &gt;&gt;
          </Button>
        </div>
        {(!isMobile) && (
            <div className="accapp-right col-2"></div>
        )}
      </div>
    );
  }
}

export default withRouter(AccAppNumbersNeedsPage);

/*
        noOfAdults: {
            type: Sequelize.INTEGER
        },
        noOfOlderChildren: {
            type: Sequelize.INTEGER
        },
        noOfChildren: {
            type: Sequelize.INTEGER
        },
        noOfInfants: {
            type: Sequelize.INTEGER
        },
        noOfPets: {
            type: Sequelize.INTEGER
        },

*/