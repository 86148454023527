import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { withRouter } from '../../components/withRouter/withRouter.tsx';
import Logo from '../../assets/images/bird-house-logo.svg';
import * as UIConstants from '../../constants/UIConstants';

import './FrontPage.css';

class FrontPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      modalTitle: "",
      modalRegisterLink: "",
      modalLoginLink: "",
      router: props.router
    }
    this.handleSurveyClick = this.handleSurveyClick.bind(this);
    this.handleRegisterClick = this.handleRegisterClick.bind(this);
    this.handleLoginClick = this.handleLoginClick.bind(this);
    this.handleOfferingClick = this.handleOfferingClick.bind(this);
    this.handleApplicationClick = this.handleApplicationClick.bind(this);
    this.handleModalRegisterClick = this.handleModalRegisterClick.bind(this);
    this.handleModalLoginClick = this.handleModalLoginClick.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  handleSurveyClick() {
    this.state.router.navigate("/survey/1");
  }

  handleRegisterClick() {
    alert(UIConstants.BUTTON_DISABLED);
  }

  handleLoginClick() {
    alert(UIConstants.BUTTON_DISABLED);
  }

  handleApplicationClick() {
    this.setState({
      modalShow: true,
      modalTitle: "Accommodation Application",
      modalRegisterLink: "/accapp/register",
      modalLoginLink: "/accapp/login"
    });
  }

  handleOfferingClick() {
    this.setState({
      modalShow: true,
      modalTitle: "Accommodation Offering",
      modalRegisterLink: "/offering/register",
      modalLoginLink: "/offering/login"
    });
  }

  handleModalRegisterClick() {
    this.state.router.navigate(this.state.modalRegisterLink);
  }

  handleModalLoginClick() {
    this.state.router.navigate(this.state.modalLoginLink);
  }

  hideModal() {
    this.setState({
      modalShow: false
    });
  }

  render() {
    let { modalShow, modalTitle } = this.state;
    return (
      <>
        <div className="frontpage-container">
          <div className="front-top-row">
            <Button 
              variant="outline-dark" 
              className="nw-basic-button front-top-button"
              aria-label="Do survey" 
              onClick={this.handleSurveyClick}
            >
              I want to help future responses by sharing my experience
            </Button>
            <div className="front-top-row-section">
              <Button 
                variant="outline-dark" 
                className="nw-basic-button front-top-button"
                aria-label="Register" 
                onClick={this.handleRegisterClick}
              >
                Register
              </Button>
              <Button 
                variant="outline-dark" 
                className="nw-basic-button front-top-button" 
                aria-label="Login"
                onClick={this.handleLoginClick}
              >
                Login
              </Button>
            </div>
          </div>
          <div className="front-logo-container">
            <img src={Logo} alt="Logo" className="front-logo"/>
            <h1 className="nw-heading">Nestwell Emergency Housing Portal</h1>
          </div>
          <div className="front-bottom">
            <Button 
              variant="outline-dark" 
              className="nw-basic-button large-front-button" 
              aria-label="Submit emergency accommodation application"
              onClick={this.handleApplicationClick}
            >
              I need emergency accommodation
            </Button>
            <Button 
              variant="outline-dark" 
              className="nw-basic-button large-front-button" 
              aria-label="Offer accommodation"
              onClick={this.handleOfferingClick}
            >
              I can offer emergency accommodation
            </Button>
          </div>
        </div>
        <Modal
          show={modalShow}
          onHide={this.hideModal}
          size="sm"
          aria-labelledby="front-modal-title"
          centered
        >
          <Modal.Header closeButton/>
          <Modal.Body>
            <div className="front-modal-content">
              <img src={Logo} alt="Logo" className="front-modal-logo"/>
              <h3 id="front-modal-title" className="front-modal-title">{modalTitle}</h3>
              <Button variant="outline-dark" className="front-modal-button" onClick={this.handleModalRegisterClick}>
                This is my first time here
              </Button>
              <Button variant="outline-dark" className="front-modal-button" onClick={this.handleModalLoginClick}>
                I've been here before & have an account
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default withRouter(FrontPage);