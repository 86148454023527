import axios from 'axios';
import Cookies from 'universal-cookie';

import * as ServerConstants from '../constants/ServerConstants';

const cookies = new Cookies();

let AuthService = {};

AuthService.isLoggedIn = () => {
    return (cookies.get(ServerConstants.LOGIN_COOKIE_KEY) === "true");
};

AuthService.logout = () => {
    return axios.post(ServerConstants.API_URL + '/auth/logout', {},
        { withCredentials: true }
    );
};

AuthService.localSignUp = (details) => {
    console.log(details);
    return axios.post(ServerConstants.API_URL + '/auth/local/signup', 
        { 
            username: details.username,
            email: details.email,
            password: details.password,
            roles: details.roles 
        }, 
        { withCredentials: true }
    );
};

AuthService.localSignIn = (username, password, loadData) => {
    let url = ServerConstants.API_URL + '/auth/local/signin';
    if (loadData) {
        url = `${url}?full=true`;
    }
    return axios.post(url, 
        { username, password }, 
        { withCredentials: true }
    );
};

AuthService.googleSignUp = () => {
    return axios.get(ServerConstants.API_URL + '/auth/google/signup',
        { withCredentials: true }
    );
};

AuthService.googleSignIn = () => {
    return axios.get(ServerConstants.API_URL + '/auth/google/signin',
        { withCredentials: true }
    );
};

AuthService.facebookSignUp = () => {
    return axios.get(ServerConstants.API_URL + '/auth/facebook/signup',
        { withCredentials: true }
    );
};

AuthService.facebookSignIn = () => {
    return axios.get(ServerConstants.API_URL + '/auth/facebook/signin',
        { withCredentials: true }
    );
};

AuthService.twitterSignUp = () => {
    return axios.get(ServerConstants.API_URL + '/auth/twitter/signup',
        { withCredentials: true }
    );
};

AuthService.twitterSignIn = () => {
    return axios.get(ServerConstants.API_URL + '/auth/twitter/signin',
        { withCredentials: true }
    );
};

AuthService.linkedinSignUp = () => {
    return axios.get(ServerConstants.API_URL + '/auth/linkedin/signup',
        { withCredentials: true }
    );
};

AuthService.linkedinSignIn = () => {
    return axios.get(ServerConstants.API_URL + '/auth/linkedin/signin',
        { withCredentials: true }
    );
};

AuthService.instagramSignUp = () => {
    return axios.get(ServerConstants.API_URL + '/auth/instagram/signup',
        { withCredentials: true }
    );
};

AuthService.instagramSignIn = () => {
    return axios.get(ServerConstants.API_URL + '/auth/instagram/signin',
        { withCredentials: true }
    );
};

AuthService.forgotPassword = (email) => {
    return axios.post(ServerConstants.API_URL + '/auth/password/forgot', 
        { email }, 
        { withCredentials: true }
    );
}

AuthService.changePassword = (newPassword) => {
    return axios.post(ServerConstants.API_URL + '/auth/password/change', 
        { newPassword }, 
        { withCredentials: true }
    );
};

AuthService.verifyCaptcha = (token, callback) => {
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          token: token
        })
      };
      fetch(ServerConstants.API_URL + '/captcha/verify', options)
      .then(async response => {
            const data = await response.json();
            // check for error response
            if (!response.ok) {
                // get error message from body or default to response status
                //const error = (data && data.message) || response.status;
                callback(false);
            } else {
                callback(data.success);
            }
      })
      .catch(error => {
        console.error('Error occurred verifying captcha', error);
        callback(false);
      });
  };

export default AuthService;
