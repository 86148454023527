/* route : /offering/1 */

import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { ToggleButton } from 'react-bootstrap';

import { withRouter } from '../../components/withRouter/withRouter.tsx';
import CustomPagination from '../../components/customPagination/CustomPagination';
import GlobalContext from '../../components/context/GlobalContext';
import LogoLeftColumn from '../../components/logoLeftColumn/LogoLeftColumn.js';
import * as AccommodationConstants from '../../constants/AccommodationConstants';

import '../../styles/offerings.css';
import './OfferBasicDetailsPage.css';

class OfferBasicDetailsPage extends React.Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);

    this.state = {
      pagination: [
        { text: "1", link: "/offering/1", alert: "", cbflag: true, active: true }, 
        { text: "2", link: "/offering/2", alert: "", cbflag: true, active: true }, 
        { text: "3", link: "/offering/3", alert: "", cbflag: true, active: true },
        { text: "4", link: "/offering/4", alert: "", cbflag: true, active: true },
        { text: "5", link: "/offering/5", alert: "", cbflag: true, active: true },
        { text: "6", link: "/offering/6", alert: "", cbflag: true, active: true }
      ],
      current: "1",
      windowWidth: window.innerWidth,
      router: props.router,
      contactFieldTitle: ""
    };
    this.updateWindowWidth = this.updateWindowWidth.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.checkPageValidation = this.checkPageValidation.bind(this);
  }

  componentDidMount() {
    this.updateWindowWidth();
    window.addEventListener('resize', this.updateWindowWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }

  updateWindowWidth() {
    this.setState({ windowWidth: window.innerWidth });
  }

  handleNextClick() {
    this.state.router.navigate("/offering/2");
  }

  handlePageClick(link, alertText) {
    this.checkPageValidation((valid, msg) => {
      if (valid) {
        if (alertText !== "") {
          alert(alertText);
        } else {
          this.state.router.navigate(link);
        }
      } else {
        this.setState({
          alertMessage: msg
        });
      }
    });
  }

  checkPageValidation(callback) {
    callback(true, "");
  }

  render() {
    let { pagination, current, windowWidth, contactFieldTitle } = this.state;

    AccommodationConstants.CONTACT_TYPES.forEach(type => {
      if (type.value === this.context.owner.preferredContactType) {
        contactFieldTitle = type.contactFieldTitle;
      }
    });

    const isMobile = (windowWidth < 768);
    return (
      <div className="offer-page-container offer-basic-details-page row">
        {(!isMobile) && (
          <LogoLeftColumn link="/" mediaDisplay={true}/>
        )}
        <div className={isMobile ? "offer-centre" : "offer-centre col-8"}>
          <CustomPagination pages={pagination} current={current} callback={(l,a) => this.handlePageClick(l,a)}/>
          <h1 className="nw-heading offer-heading">Tell Us A Few Basic Details First</h1>
          <div className="offer-section">
            <h2 className="nw-title">Your Name</h2>
            <Form.Control 
                type="text"
                className="nw-input offer-input-full"
                value={this.context.owner.name}
                onChange={(e) => {
                  this.context.setOwnerValues([{ key: "name", value: e.target.value }]);
                }}
            />
          </div>
          <div className="offer-section">
            <h2 className="nw-title">How do you prefer to be contacted?</h2>
            <div className="row offer-contact-row">
              {AccommodationConstants.CONTACT_TYPES.map((type, index) => (
                <div key={`ctype-div-${index}`} className={"col-4 " + type.class}>
                  <ToggleButton
                    key={`ctype-${index}`}
                    className={"nw-toggle-button " + type.buttonClass}
                    type="radio"
                    variant="outline-dark"
                    value={type.value}
                    checked={this.context.owner.preferredContactType === type.value}
                    onClick={(e) => {
                      this.context.setOwnerValues([{ key: "preferredContactType", value: type.value }]);
                      this.setState({ contactFieldTitle: type.contactFieldTitle });
                    }}
                  >
                    {type.label}
                  </ToggleButton>
                </div>
            ))}
            </div>
            <h2 className="nw-title">{this.state.contactFieldTitle}</h2>
            <Form.Control 
                type="text"
                className="offer-input-full"
                value={this.context.owner.contactNumberOrAccount}
                onChange={(e) => {
                  this.context.setOwnerValues([{ key: "contactNumberOrAccount", value: e.target.value }]);
                }}
            />
          </div>
          <div className="offer-section">
            <h2 className="nw-title">The address of the accommodation you are offering</h2>
            <Form.Control 
                type="text"
                className="offer-input-full"
                value={this.context.accommodation.addressStreet1}
                onChange={(e) => {
                  this.context.setAccommodationValues([{ key: "addressStreet1", value: e.target.value}]);
                }}
            />
            <Form.Control 
                type="text"
                className="offer-input-full"
                value={this.context.accommodation.addressStreet2}
                onChange={(e) => {
                  this.context.setAccommodationValues([{ key: "addressStreet2", value: e.target.value}]);
                }}
            />
            <div className="offer-address-row">
              <Form.Control 
                  type="text"
                  className="nw-input offer-locality"
                  value={this.context.accommodation.addressLocality}
                  onChange={(e) => {
                    this.context.setAccommodationValues([{ key: "addressLocality", value: e.target.value}]);
                  }}
              />
              <Form.Select 
                className="offer-state-code"
                value={this.context.accommodation.addressState}
                onChange={(e) => {
                  this.context.setAccommodationValues([{ key: "addressState", value: e.target.value}]);
                }}
                aria-label="State" 
              >
                <option></option>
                <option>ACT</option>
                <option>NSW</option>
                <option>NT</option>
                <option>QLD</option>
                <option>SA</option>
                <option>TAS</option>
                <option>VIC</option>
                <option>WA</option>
              </Form.Select>
              <Form.Control 
                  type="text"
                  value={this.context.accommodation.addressPostcode}
                  className="offer-state-code"
                  onChange={(e) => {
                    this.context.setAccommodationValues([{ key: "addressPostcode", value: e.target.value}]);
                  }}
              />
            </div>
          </div>
          <p className="offer-text">You will now go through a series of screens where you can tell us more about the accommodation you are offering.</p>
          <p className="offer-text">You can save your answers and leave at any time then return to complete them later.</p>
          <Button 
            variant="outline-dark" 
            className="nw-submit-button offer-bottom-button" 
            aria-label="Go to next page"
            onClick={this.handleNextClick}
          >
            Next &gt;&gt;
          </Button>
        </div>
        {(!isMobile) && (
            <div className="offer-right col-2"></div>
        )}
      </div>
    );
  }
}

export default withRouter(OfferBasicDetailsPage);