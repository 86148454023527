import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';

import { withRouter } from '../../components/withRouter/withRouter.tsx';
import CustomPagination from '../../components/customPagination/CustomPagination';
import GlobalContext from '../../components/context/GlobalContext';
import LogoLeftColumn from '../../components/logoLeftColumn/LogoLeftColumn.js';
import * as AccApplicationConstants from '../../constants/AccApplicationConstants.js';

import '../../styles/applications.css';
import './AccAppBasicDetailsPage.css';

class AccAppBasicDetailsPage extends React.Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    this.state = {
      pagination: [
        { text: "1", link: "/accapp/1", alert: "", cbflag: true, active: true }, 
        { text: "2", link: "/accapp/2", alert: "", cbflag: true, active: true }, 
        { text: "3", link: "/accapp/3", alert: "", cbflag: true, active: true },
        { text: "4", link: "/accapp/4", alert: "", cbflag: true, active: true }
      ],
      current: "1",
      windowWidth: window.innerWidth,
      router: props.router
    };
    this.updateWindowWidth = this.updateWindowWidth.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  componentDidMount() {
    this.updateWindowWidth();
    window.addEventListener('resize', this.updateWindowWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }

  updateWindowWidth() {
    this.setState({ windowWidth: window.innerWidth });
  }

  handleNextClick() {
    this.state.router.navigate("/accapp/2");
  }

  handlePageClick(link, alertText) {
    this.checkPageValidation((valid, msg) => {
      if (valid) {
        if (alertText !== "") {
          alert(alertText);
        } else {
          this.state.router.navigate(link);
        }
      } else {
        this.setState({
          alertMessage: msg
        });
      }
    });
  }

  checkPageValidation(callback) {
    /* TODO: Add functionality to handle validation */
    callback(true, "");
  }

  render() {
    let { windowWidth, pagination, current } = this.state;
    const isMobile = (windowWidth < 768);
    return (
      <div className="accapp-page-container app-basic-details-page row">
        {(!isMobile) && (
          <LogoLeftColumn link="/" mediaDisplay={false}/>
        )}
        <div className={isMobile ? "accapp-centre" : "accapp-centre col-8"}>
          <CustomPagination pages={pagination} current={current} callback={(l,a) => this.handlePageClick(l,a)}/>
          <h1 className="nw-heading">Tell Us A Few Basic Details First</h1>
          <div className="accapp-section">
            <h2 className="nw-title">Your name</h2>
            <Form.Control 
              type="text"
              className="nw-input accapp-input-full"
              value={this.context.accomApplicant.name}
              onChange={(e) => {
                this.context.setAccomApplicantValues([{ key: "name", value: e.target.value }]);
              }}
            />
          </div>
          <div className="accapp-section">
            <h2 className="nw-title">What address were you living/staying at when the disaster struck?</h2>
            <Form.Control 
                type="text"
                className="accapp-input-full"
                value={this.context.accomApplication.previousAddressStreet1}
                onChange={(e) => {
                  this.context.setAccomApplicationValues([{ key: "previousAddressStreet1", value: e.target.value }]);
                }}
            />
            <Form.Control 
                type="text"
                className="accapp-input-full"
                value={this.context.accomApplication.previousAddressStreet2}
                onChange={(e) => {
                  this.context.setAccomApplicationValues([{ key: "previousAddressStreet2", value: e.target.value }]);
                }}
            />
            <div className="accapp-address-row">
              <Form.Control 
                  type="text"
                  className="nw-input accapp-locality"
                  value={this.context.accomApplication.previousAddressLocality}
                  onChange={(e) => {
                    this.context.setAccomApplicationValues([{ key: "previousAddressLocality", value: e.target.value }]);
                  }}
              />
              <Form.Select
                className="accapp-state-code"
                value={this.context.accomApplication.previousAddressState}
                onChange={(e) => {
                  this.context.setAccomApplicationValues([{ key: "previousAddressState", value: e.target.value }]);
                }}
                aria-label="State" 
              >
                <option></option>
                <option>ACT</option>
                <option>NSW</option>
                <option>NT</option>
                <option>QLD</option>
                <option>SA</option>
                <option>TAS</option>
                <option>VIC</option>
                <option>WA</option>
              </Form.Select>
              <Form.Control 
                  type="text"
                  className="accapp-state-code"
                  value={this.context.accomApplication.previousAddressPostcode}
                  onChange={(e) => {
                    this.context.setAccomApplicationValues([{ key: "previousAddressPostcode", value: e.target.value }]);
                  }}
              />
            </div>
          </div>
          <div className="accapp-section">
            <h2 className="nw-title">Which of these best describes why you need emergency accommodation?</h2>
            {AccApplicationConstants.ACCOMMODATION_REASONS.map((reason, index) => (
              <ButtonGroup key={`accapp-reason-group-${index}`} className="accapp-reason-button-group" aria-label={reason.label}>
                <ToggleButton
                  key={`accapp-reason-${index}`}
                  className="nw-toggle-button accapp-reason-button"
                  variant="outline-dark"
                  type="radio"
                  checked={this.context.accomApplication.applicationReason === reason.value}
                  onChange={(e) => {
                    this.context.setAccomApplicationValues([{ key: "applicationReason", value: e.target.value }]);
                  }}
                >
                  {reason.label}
                </ToggleButton>
              </ButtonGroup>
            ))}
            <h2 className="nw-title">If other:</h2>
            <Form.Control 
              type="text"
              className="accapp-input-full"
              value={this.context.accomApplication.applicationReasonOther}
              onChange={(e) => {
                this.context.setAccomApplicationValues([{ key: "applicationReasonOther", value: e.target.value }]);
              }}
            />
          </div>
          <Button 
            variant="outline-dark" 
            className="nw-submit-button accapp-bottom-button" 
            aria-label="Go to next page"
            onClick={this.handleNextClick}
          >
            Next &gt;&gt;
          </Button>
        </div>
        {(!isMobile) && (
            <div className="accapp-right col-2"></div>
        )}
      </div>
    );
  }
}

export default withRouter(AccAppBasicDetailsPage);