/* route : /offering/4 */

import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { ToggleButton } from 'react-bootstrap';
import { ButtonGroup } from 'react-bootstrap';

import { withRouter } from '../../components/withRouter/withRouter.tsx';
import LogoLeftColumn from '../../components/logoLeftColumn/LogoLeftColumn.js';
import CustomPagination from '../../components/customPagination/CustomPagination';
import GlobalContext from '../../components/context/GlobalContext';
import DataService from '../../services/DataService.js';
import * as AccommodationConstants from '../../constants/AccommodationConstants';

import '../../styles/offerings.css';
import './OfferAccessibilityPage.css';

class OfferAccessibilityPage extends React.Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    this.state = {
      pagination: [
        { text: "1", link: "/offering/1", alert: "", cbflag: true, active: true }, 
        { text: "2", link: "/offering/2", alert: "", cbflag: true, active: true }, 
        { text: "3", link: "/offering/3", alert: "", cbflag: true, active: true },
        { text: "4", link: "/offering/4", alert: "", cbflag: true, active: true },
        { text: "5", link: "/offering/5", alert: "", cbflag: true, active: true },
        { text: "6", link: "/offering/6", alert: "", cbflag: true, active: true }
      ],
      current: "4",
      windowWidth: window.innerWidth,
      router: props.router
    };
    this.updateWindowWidth = this.updateWindowWidth.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);    
    this.handleNextClick = this.handleNextClick.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.checkPageValidation = this.checkPageValidation.bind(this);
  }

  componentDidMount() {
    this.updateWindowWidth();
    window.addEventListener('resize', this.updateWindowWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }

  updateWindowWidth() {
    this.setState({ windowWidth: window.innerWidth });
  }

  handleSaveClick() {
    DataService.saveOfferData(this.context)
    .then(response => {
      if (response.data) {
        if (response.data.owner) {
          this.context.setOwnerValues([{ key: "id", value: response.data.owner.id}]);
        }
        if (response.data.accommodation) {
          this.context.setAccommodationValues([{ key: "id", value: response.data.owner.id}]);
        }
        if (response.data.accomAmenities) {
          this.context.setAccomAmenities(response.data.accomAmenities);
        }
        if (response.data.rooms) {
          this.context.setRooms(response.data.rooms);
        }
      }
      this.state.router.navigate("/");
    })
    .catch(error => {
      console.error('Error occurred saving survey', error);
      alert("Unable to save data : " + error);
    });
  }

  handleNextClick() {
    this.state.router.navigate("/offering/5");
  }

  handlePageClick(link, alertText) {
    this.checkPageValidation((valid, msg) => {
      if (valid) {
        if (alertText !== "") {
          alert(alertText);
        } else {
          this.state.router.navigate(link);
        }
      } else {
        this.setState({
          alertMessage: msg
        });
      }
    });
  }

  checkPageValidation(callback) {
    /* TODO: Add functionality to handle validation */
    callback(true, "");
  }

  render() {
    let { pagination, current, windowWidth } = this.state;
    const isMobile = (windowWidth < 768);
    return (
      <div className="offer-page-container offer-accessibility-page row">
        {(!isMobile) && (
          <LogoLeftColumn link="/" mediaDisplay={false}/>
        )}
        <div className={isMobile ? "offer-centre" : "offer-centre col-8"}>
          <CustomPagination pages={pagination} current={current} callback={(l,a) => this.handlePageClick(l,a)}/>
          <h1 className="nw-heading offer-heading">Tell Us More About The Accommodation</h1>
          <div className="offer-section">
            <div className="offer-yes-no-block">
              <h2 className="nw-title">Is the accommodation child-friendly?</h2>
              <ButtonGroup aria-label="Child friendly - yes or no">
                <ToggleButton
                  className="nw-toggle-button offer-yes-no-button"
                  type="radio"
                  variant="outline-dark"
                  checked={this.context.accommodation.childFriendly === "yes"}
                  onClick={(e) => {
                    this.context.setAccommodationValues([{ key: "childFriendly", value: "yes" }]);
                  }}
                >
                  Yes
                </ToggleButton>
                <ToggleButton
                  className="nw-toggle-button offer-yes-no-button"
                  type="radio"
                  variant="outline-dark"
                  checked={this.context.accommodation.childFriendly === "no"}
                  onClick={(e) => {
                    this.context.setAccommodationValues([{ key: "childFriendly", value: "no" }]);
                  }}
                >
                  No
                </ToggleButton>
              </ButtonGroup>
            </div>
          </div>
          <div className="offer-section">
            <div className="offer-yes-no-block">
              <h2 className="nw-title">Is the accommodation accessible to anyone with mobility problems?</h2>
              <ButtonGroup aria-label="Mobility accessible - yes or no">
                <ToggleButton
                  className="nw-toggle-button offer-yes-no-button"
                  type="radio"
                  variant="outline-dark"
                  checked={this.context.accommodation.mobilityAccessible === "yes"}
                  onClick={(e) => {
                    this.context.setAccommodationValues([{ key: "mobilityAccessible", value: "yes" }]);
                  }}
                >
                  Yes
                </ToggleButton>
                <ToggleButton
                  className="nw-toggle-button offer-yes-no-button"
                  type="radio"
                  variant="outline-dark"
                  checked={this.context.accommodation.mobilityAccessible === "no"}
                  onClick={(e) => {
                    this.context.setAccommodationValues([{ key: "mobilityAccessible", value: "no" }]);
                  }}
                >
                  No
                </ToggleButton>
              </ButtonGroup>
            </div>
          </div>
          <div className="offer-section">
            <div className="offer-yes-no-block">
              <h2 className="nw-title">Is the accommodation pet-friendly?</h2>
              <ButtonGroup aria-label="Pet friendly - yes or no">
                <ToggleButton
                  className="nw-toggle-button offer-yes-no-button"
                  type="radio"
                  variant="outline-dark"
                  checked={this.context.accommodation.petFriendly === "yes"}
                  onClick={(e) => {
                    this.context.setAccommodationValues([{ key: "petFriendly", value: "yes" }]);
                  }}
                >
                  Yes
                </ToggleButton>
                <ToggleButton
                  className="nw-toggle-button offer-yes-no-button"
                  type="radio"
                  variant="outline-dark"
                  checked={this.context.accommodation.petFriendly === "no"}
                  onClick={(e) => {
                    this.context.setAccommodationValues([{ key: "petFriendly", value: "no" }]);
                  }}
                >
                  No
                </ToggleButton>
              </ButtonGroup>
            </div>
          </div>
          <div className="offer-section">
            <div className="offer-yes-no-block">
              <h2 className="nw-title">Are you able to take on livestock?</h2>
              <ButtonGroup aria-label="Take on livestock - yes or no">
                <ToggleButton
                  className="nw-toggle-button offer-yes-no-button"
                  type="radio"
                  variant="outline-dark"
                  checked={this.context.accommodation.acceptingLivestock === "yes"}
                  onClick={(e) => {
                    this.context.setAccommodationValues([{ key: "acceptingLivestock", value: "yes" }]);
                  }}
                >
                  Yes
                </ToggleButton>
                <ToggleButton
                  className="nw-toggle-button offer-yes-no-button"
                  type="radio"
                  variant="outline-dark"
                  checked={this.context.accommodation.acceptingLivestock === "no"}
                  onClick={(e) => {
                    this.context.setAccommodationValues([{ key: "acceptingLivestock", value: "no" }]);
                  }}
                >
                  No
                </ToggleButton>
              </ButtonGroup>
            </div>
          </div>
          <div className="offer-section">
            <h2 className="nw-title">Do you require stairs to enter the accommodation?</h2>
            <ButtonGroup aria-label="Entry stairs">
              {AccommodationConstants.ENTRY_STAIRS.map((entry, index) => (
                <ToggleButton
                  key={`stairs-${index}`}
                  className="nw-toggle-button"
                  type="radio"
                  variant="outline-dark"
                  value={entry.value}
                  checked={this.context.accommodation.entryStairs === entry.value}
                  onClick={(e) => {
                    this.context.setAccommodationValues([{ key: "entryStairs", value: entry.value }]);
                  }}
                >
                  {entry.label}
                </ToggleButton>
            ))}
            </ButtonGroup>
          </div>
          <div className="offer-section">
            <h2 className="nw-title">What flooring makes up most of the interior of the accommodation?</h2>
            <ButtonGroup aria-label="Interior flooring type">
              {AccommodationConstants.FLOORING_TYPES.map((type, index) => (
                <ToggleButton
                  key={`flooring-${index}`}
                  className="nw-toggle-button"
                  type="radio"
                  variant="outline-dark"
                  value={type.value}
                  checked={this.context.accommodation.flooring === type.value}
                  onClick={(e) => {
                    this.context.setAccommodationValues([{ key: "flooring", value: type.value }]);
                  }}
                >
                  {type.label}
                </ToggleButton>
            ))}
            </ButtonGroup>
          </div>
          <div className="offer-section">
            <h2 className="nw-title">What covering makes up the ground leading up to the front door?</h2>
            <Form.Select 
              className="offer-select"
              value={this.context.accommodation.entranceGroundCover}
              onChange={(e) => {
                this.context.setAccommodationValues([{ key: "entranceGroundCover", value: e.target.value }]);
              }}
              aria-label="Ground Covering" 
            >
              {AccommodationConstants.GROUND_COVERINGS.map((covering, index) => (
                <option key={`covering-${index}`} value={covering.value}>{covering.label}</option>
              ))}
            </Form.Select>
          </div>
          <div className="offer-button-row">
            <Button 
                variant="outline-dark" 
                className="nw-submit-button offer-bottom-button" 
                aria-label="Save and leave"
                onClick={() => {}}
              >
                Save &amp; Leave
              </Button>
              <Button 
                variant="outline-dark" 
                className="nw-submit-button offer-bottom-button" 
                aria-label="Go to next page"
                onClick={this.handleNextClick}
              >
                Next &gt;&gt;
              </Button>
          </div>
        </div>
        {(!isMobile) && (
            <div className="offer-right col-2"></div>
        )}
      </div>
    );
  }
}

export default withRouter(OfferAccessibilityPage);