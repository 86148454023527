import React from 'react';

import GlobalContext from '../context/GlobalContext';

import './MapMediaDisplay.css';

export default class MapMediaDisplay extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);
    }
    
    render() {
        return (
            <>
            </>
        );
    }
}
