export const BUTTON_DISABLED = "This button has not been enabled in this version";
export const SURVEY_BUTTON_LABEL = "Tell us your story to help improve the experience";
export const SURVEY_PAGE_INCOMPLETE = "You need to complete this page of the survey to continue";
export const SURVEY_PAGE_ERRORS = "There an errors on this page of the survey. You need to fix them before continuing.";

export const EMAIL_INVALID_ERROR = "Please enter a valid email address";
export const DIFFERENT_PASSWORDS_ERROR = "Your password do not match. Please re-enter them.";

export const NO_USERNAME_ERROR = "No username provided";
export const NO_PASSWORD_ERROR = "No password provided";

export const CAPTCHA_ERROR = "Unable to confirm you are not a robot. Please try again.";

export const REGISTER_ERROR = "Unfortunately we are unable to complete registration at this time. Please try again later.";

export const LOGIN_ERROR = "Unfortunately we are unable to log you in at this time. Please try again later.";

export const WARNING_LEVEL_LABEL_GENERIC = "When you left your home or property due to natural disaster, what was the warning level given at the time? *";
export const WARNING_LEVEL_LABEL_FIRE = "When you left your home or property due to a bushfire threat, what was the warning level given by the RFS at the time? *";
export const WARNING_LEVEL_LABEL_FLOOD = "When you left your home or property due to flooding, what was the warning level given by the SES at the time? *";

export const SHELTER_LABEL_GENERIC = "How did you shelter when you left your home or property? *";
export const SHELTER_LABEL_FIRE = "How did you shelter when you left your home or property due to bushfire threat? *";
export const SHELTER_LABEL_FLOOD = "How did you shelter when you left your home or property due to flooding? *";

export const SHELTER_SATISFACTION_LABEL_GENERIC = "Were you satisfied with where you sheltered during the natural disaster? *";
export const SHELTER_SATISFACTION_LABEL_FIRE = "Were you satisfied with where you sheltered during the bushfire threat? *";
export const SHELTER_SATISFACTION_LABEL_FLOOD = "Were you satisfied with where you sheltered during the flood? *";

export const SHELTER_REPEAT_LABEL_GENERIC = "Would you shelter in the same way again in the event of a natural disaster? *";
export const SHELTER_REPEAT_LABEL_FIRE = "Would you shelter in the same way again in the event of a bushfire threat? *";
export const SHELTER_REPEAT_LABEL_FLOOD = "Would you shelter in the same way again in the event of flooding? *";