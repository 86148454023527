import React from 'react';
import { Navigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

import { withRouter } from '../../components/withRouter/withRouter.tsx';
import LogoLeftColumn from '../../components/logoLeftColumn/LogoLeftColumn.js';
import CustomPagination from '../../components/customPagination/CustomPagination';
import GlobalContext from '../../components/context/GlobalContext';

import '../../styles/surveys.css';
import './SurveyCompletePage.css';

class SurveyCompletePage extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = {
            pagination : [
              { text: "1", link: "", alert: "", cbflag: false, active: false }, 
              { text: "2", link: "", alert: "", cbflag: false, active: false }, 
              { text: "3", link: "", alert: "", cbflag: false, active: false }
            ],
            current : "3",
            navigate: null,
            windowWidth: window.innerWidth,
            router: props.router
        };
        this.handleRestartClick = this.handleRestartClick.bind(this);
        this.updateWindowWidth = this.updateWindowWidth.bind(this);
    }

    componentDidMount() {
        this.updateWindowWidth();
        window.addEventListener('resize', this.updateWindowWidth);
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowWidth);
    }

    updateWindowWidth() {
        this.setState({ windowWidth: window.innerWidth });
    }

    handleRestartClick() {
        let clearSet = [
            { key: "id", value: 0 },
            { key: "lga", value: "" },
            { key: "evacuationReason", value: "" },
            { key: "warningLevel", value: "" },
            { key: "shelterOnEvacuation", value: "" },
            { key: "shelterOnEvacuationOther", value: "" },
            { key: "shelterSatisfaction", value: "" },
            { key: "shelterSatisfactionReason", value: "" },
            { key: "shelterRepeat", value: "" },
            { key: "shelterRepeatReason", value: "" },
            { key: "evacueeHost", value: "" },
            { key: "howEvacueeFound", value: "" },
            { key: "hostRepeat", value: "" },
            { key: "hostRepeatReason", value: "" },
            { key: "contactLater", value: "" },
            { key: "contactEmail", value: "" },
            { key: "contactPhone", value: "" }
        ];
        this.context.setSurveyValues(clearSet);
        this.state.router.navigate("/survey/1");
      }
    
    render() {
        let { pagination, current, navigate, windowWidth } = this.state;
        if (this.context.survey.id === 0) {
            navigate = "/survey/1";
        }
        if (navigate) {
            return (<Navigate to={navigate}/>);
        } else {
            const isMobile = (windowWidth < 768);
            return ( 
                <div className="surveypage-container survey-complete-page row">
                    {(!isMobile) && (
                        <LogoLeftColumn link="/" mediaDisplay={false}/>
                    )}
                    <div className={isMobile ? "survey-centre" : "survey-centre col-8"}>
                        <CustomPagination pages={pagination} current={current}/>
                        <h1 className="nw-heading">Thank you</h1>
                        <div className="survey-complete-text">
                            Thank you for sharing your experience with us. Some people may find the content has brought up distressing memories or
                            feelings of things you may have experienced.<br/>
                            <br/>
                            If you or anyone you know needs help:<br/>
                            <br/>
                            Lifeline on 13 11 14<br/>
                            Kids Helpline on 1800 551 800<br/>
                            MensLine Australia on 1300 789 978<br/>
                            Suicide Call Back Service on 1300 659 467<br/>
                            Beyond Blue on 1300 224 636<br/>
                            Headspace on 1800 650 890<br/>
                            ReachOut at <a href="https://au.reachout.com/">au.reachout.com</a><br/>
                            Care Leavers Australasia Network (CLAN) on 1800 008 774<br/>
                            <br/>
                            If you have a different experience that you wish to share with us, press the button below to complete another survey.
                        </div>
                        <Button 
                            variant="outline-dark" 
                            className="nw-submit-button nw-bottom-button" 
                            aria-label="Go to next page"
                            onClick={this.handleRestartClick}
                        >
                            Share Another Experience &gt;&gt;
                        </Button>
                    </div>
                    {(!isMobile) && (
                        <div className="survey-right col-2"></div>
                    )}
                </div>
            );
        }
    }
}

export default withRouter(SurveyCompletePage);