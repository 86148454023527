/* route : /offering/3 */

import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { ToggleButton } from 'react-bootstrap';
import { ButtonGroup } from 'react-bootstrap';

import { withRouter } from '../../components/withRouter/withRouter.tsx';
import LogoLeftColumn from '../../components/logoLeftColumn/LogoLeftColumn.js';
import CustomPagination from '../../components/customPagination/CustomPagination';
import GlobalContext from '../../components/context/GlobalContext';
import DataService from '../../services/DataService.js';
import * as AccommodationConstants from '../../constants/AccommodationConstants';

import '../../styles/offerings.css';
import './OfferPeriodCapacityPage.css';

class OfferPeriodCapacityPage extends React.Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    this.state = {
      pagination: [
        { text: "1", link: "/offering/1", alert: "", cbflag: true, active: true }, 
        { text: "2", link: "/offering/2", alert: "", cbflag: true, active: true }, 
        { text: "3", link: "/offering/3", alert: "", cbflag: true, active: true },
        { text: "4", link: "/offering/4", alert: "", cbflag: true, active: true },
        { text: "5", link: "/offering/5", alert: "", cbflag: true, active: true },
        { text: "6", link: "/offering/6", alert: "", cbflag: true, active: true }
      ],
      current: "3",
      windowWidth: window.innerWidth,
      router: props.router
    };
    this.updateWindowWidth = this.updateWindowWidth.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.checkPageValidation = this.checkPageValidation.bind(this);
  }

  componentDidMount() {
    this.updateWindowWidth();
    window.addEventListener('resize', this.updateWindowWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }

  updateWindowWidth() {
    this.setState({ windowWidth: window.innerWidth });
  }

  handleSaveClick() {
    DataService.saveOfferData(this.context)
    .then(response => {
      if (response.data) {
        if (response.data.owner) {
          this.context.setOwnerValues([{ key: "id", value: response.data.owner.id}]);
        }
        if (response.data.accommodation) {
          this.context.setAccommodationValues([{ key: "id", value: response.data.owner.id}]);
        }
        if (response.data.accomAmenities) {
          this.context.setAccomAmenities(response.data.accomAmenities);
        }
        if (response.data.rooms) {
          this.context.setRooms(response.data.rooms);
        }
      }
      this.state.router.navigate("/");
    })
    .catch(error => {
      console.error('Error occurred saving survey', error);
      alert("Unable to save data : " + error);
    });
  }

  handleNextClick() {
    this.state.router.navigate("/offering/4");
  }

  handlePageClick(link, alertText) {
    this.checkPageValidation((valid, msg) => {
      if (valid) {
        if (alertText !== "") {
          alert(alertText);
        } else {
          this.state.router.navigate(link);
        }
      } else {
        this.setState({
          alertMessage: msg
        });
      }
    });
  }

  checkPageValidation(callback) {
    /* TODO: Add functionality to handle validation */
    callback(true, "");
  }

  render() {
    let { pagination, current, windowWidth } = this.state;
    const isMobile = (windowWidth < 768);
    return (
      <div className="offer-page-container offer-basic-details-page row">
        {(!isMobile) && (
          <LogoLeftColumn link="/" mediaDisplay={true}/>
        )}
        <div className={isMobile ? "offer-centre" : "offer-centre col-8"}>
          <CustomPagination pages={pagination} current={current} callback={(l,a) => this.handlePageClick(l,a)}/>
          <h1 className="nw-heading offer-heading">Tell Us More About The Accommodation</h1>
          <div className="offer-section">
            <div className="offer-period-row">
              <h2 className="nw-title offer-period-question">How long would be willing to accommodate someone who is unable to return to their home / property due to a disaster?</h2>
              <Form.Control 
                type="text"
                className="nw-input offer-period-amount"
                value={this.context.accommodation.offeringTime}
                onChange={(e) => {
                  this.context.setAccommodationValues([{ key: "offeringTime", value: e.target.value }]);
                }}
              />
              <Form.Select 
                className="offer-select offer-period-type"
                value={this.context.accommodation.offeringPeriod}
                onChange={(e) => {
                  this.context.setAccommodationValues([{ key: "offeringPeriod", value: e.target.value }]);
                }}
                aria-label="Period Type"
              >
                <option></option>
                <option>Days</option>
                <option>Weeks</option>
                <option>Months</option>
              </Form.Select>
            </div>
          </div>
          <div className="offer-section">
            <div className="offer-row">
              <h2 className="nw-title offer-row-title-1">How many people would you be able to accommodate?</h2>
              <Form.Control 
                type="text"
                className="nw-input offer-accom-total"
                value={this.context.accommodation.maximumOccupancy}
                onChange={(e) => {
                  this.context.setAccommodationValues([{ key: "maximumOccupancy", value: e.target.value }]);
                }}
              />
            </div>
          </div>
          <div className="offer-section">
            <div className="offer-yes-no-block">
              <h2 className="nw-title offer-row-title-1">Is the accommodation furnished?</h2>
              <ButtonGroup aria-label="Active listing - yes or no">
                <ToggleButton
                  className="nw-toggle-button offer-yes-no-button"
                  type="radio"
                  variant="outline-dark"
                  checked={this.context.accommodation.furnished === "yes"}
                  onClick={(e) => {
                    this.context.setAccommodationValues([{ key: "furnished", value: "yes" }]);
                  }}
                >
                  Yes
                </ToggleButton>
                <ToggleButton
                  className="nw-toggle-button offer-yes-no-button"
                  type="radio"
                  variant="outline-dark"
                  checked={this.context.accommodation.furnished === "no"}
                  onClick={(e) => {
                    this.context.setAccommodationValues([{ key: "furnished", value: "no" }]);
                  }}
                >
                  No
                </ToggleButton>
              </ButtonGroup>
            </div>
          </div>
          <div className="offer-section">
            <h2 className="nw-title">What additional amenities might be needed to make the accommodation liveable?</h2>
            <Form.Control 
              as="textarea"
              rows={2}
              className="nw-input"
              aria-label="Enter additonal amenities description"
              value={this.context.accommodation.amenitiesRequired}
              onChange={(e) => {
                this.context.setAccommodationValues([{ key: "amenitiesRequired", value: e.target.value }]);
              }}
            />
          </div>
          <div className="offer-section">
            <div className="offer-room-number-row">
              <h2 className="nw-title offer-room-number-question">How many bedrooms are available in the accommodation?</h2>
              <ButtonGroup aria-label="Number of bedrooms" className="offer-room-number-button-group">
                {AccommodationConstants.ROOM_COUNT_VALUES.map((count, index) => (
                  <ToggleButton
                    key={`bedrooms-${index}`}
                    className="nw-toggle-button offer-room-number-button"
                    type="radio"
                    variant="outline-dark"
                    value={count.value}
                    checked={this.context.accommodation.noOfBedrooms === count.value}
                    onClick={(e) => {
                      this.context.setAccommodationValues([{ key: "noOfBedrooms", value: count.value }]);
                    }}
                  >
                    {count.label}
                  </ToggleButton>
                ))}
              </ButtonGroup>
            </div>
          </div>
          <div className="offer-section">
            <div className="offer-room-number-row">
                <h2 className="nw-title offer-room-number-question">How many bathrooms are there in your accommodation?</h2>
                <ButtonGroup aria-label="Number of bathrooms" className="offer-room-number-button-group">
                {AccommodationConstants.ROOM_COUNT_VALUES.map((count, index) => (
                    <ToggleButton
                      key={`bedrooms-${index}`}
                      className="nw-toggle-button offer-room-number-button"
                      type="radio"
                      variant="outline-dark"
                      value={count.value}
                      checked={this.context.accommodation.noOfBathrooms === count.value}
                      onClick={(e) => {
                        this.context.setAccommodationValues([{ key: "noOfBathrooms", value: count.value }]);
                      }}
                    >
                      {count.label}
                    </ToggleButton>
                  ))}
                </ButtonGroup>
              </div>
            </div>
          <div className="offer-section">
            <div className="offer-yes-no-block">
              <h2 className="nw-title">Are there kitchen / cooking facilities in your accommodation?</h2>
              <ButtonGroup aria-label="Kitchen facilities - yes or no">
                <ToggleButton
                  className="nw-toggle-button offer-yes-no-button"
                  type="radio"
                  variant="outline-dark"
                  checked={this.context.accommodation.kitchenFacilities === "yes"}
                  onClick={(e) => {
                    this.context.setAccommodationValues([{ key: "kitchenFacilities", value: "yes" }]);
                  }}
                >
                  Yes
                </ToggleButton>
                <ToggleButton
                  className="nw-toggle-button offer-yes-no-button"
                  type="radio"
                  variant="outline-dark"
                  checked={this.context.accommodation.kitchenFacilities === "no"}
                  onClick={(e) => {
                    this.context.setAccommodationValues([{ key: "kitchenFacilities", value: "no" }]);
                  }}
                >
                  No
                </ToggleButton>
              </ButtonGroup>
            </div>
          </div>
          <div className="offer-button-row">
            <Button 
                variant="outline-dark" 
                className="nw-submit-button offer-bottom-button" 
                aria-label="Save and leave"
                onClick={() => {}}
              >
                Save &amp; Leave
              </Button>
              <Button 
                variant="outline-dark" 
                className="nw-submit-button offer-bottom-button" 
                aria-label="Go to next page"
                onClick={this.handleNextClick}
              >
                Next &gt;&gt;
              </Button>
          </div>
        </div>
        {(!isMobile) && (
            <div className="offer-right col-2"></div>
        )}
      </div>
    );
  }
}

export default withRouter(OfferPeriodCapacityPage);