import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';

import './CustomPagination.css';

export default class CustomPaginationButton extends React.Component {
    static propTypes = {
        text: PropTypes.string,
        link: PropTypes.string,
        alert: PropTypes.string,
        cbflag: PropTypes.bool,
        handleClick: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.state = {
            text: props.text,
            link: props.link,
            alert: props.alert,
            cbflag: props.cbflag
        }
    }

    render() {
        return (
            <Button variant="outline-dark" className="nw-cust-pg" onClick={() => this.props.handleClick(this.state.cbflag, this.state.link, this.state.alert)}>
                {this.state.text}
            </Button>
        );
    }
}