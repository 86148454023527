import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { GrDrag } from 'react-icons/gr';
import { BsXLg } from 'react-icons/bs';

import { withRouter } from '../../components/withRouter/withRouter.tsx';
import CustomPagination from '../../components/customPagination/CustomPagination';
import GlobalContext from '../../components/context/GlobalContext';
import LogoLeftColumn from '../../components/logoLeftColumn/LogoLeftColumn.js';
import * as AccApplicationConstants from '../../constants/AccApplicationConstants.js';

import '../../styles/applications.css';
import './AccAppContactsPage.css';
import { ToggleButton } from 'react-bootstrap';

const spacing = 10;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  margin: `0 0 ${spacing}px 0`,

  // change background colour if dragging
  background: "#FFF",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "#EEE" : "#FFF",
  padding: spacing,
  width: "100%",
  marginBottom: "0.5em"
});

class AccAppContactsPage extends React.Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    this.state = {
      pagination: [
        { text: "1", link: "/accapp/1", alert: "", cbflag: true, active: true }, 
        { text: "2", link: "/accapp/2", alert: "", cbflag: true, active: true }, 
        { text: "3", link: "/accapp/3", alert: "", cbflag: true, active: true },
        { text: "4", link: "/accapp/4", alert: "", cbflag: true, active: true }
      ],
      current: "2",
      windowWidth: window.innerWidth,
      router: props.router,
      contactMethods: [],
      emergContactIndex: -1,
      emergContactName: "",
      emergContactAddress1: "",
      emergContactAddress2: "",
      emergContactLocality: "",
      emergContactState: "",
      emergContactPostcode: "",
      emergContactPhone: "",
      emergContainerClass: "emerg-contact-input-block invisible"
    };
    this.updateWindowWidth = this.updateWindowWidth.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.addContactMethod = this.addContactMethod.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.getItemContent = this.getItemContent.bind(this);
    this.onMethodSelectChange = this.onMethodSelectChange.bind(this);
    this.onMethodInputChange = this.onMethodInputChange.bind(this);
    this.onMethodDeleteClick = this.onMethodDeleteClick.bind(this);
    this.addEmergContact = this.addEmergContact.bind(this);
    this.changeEmergContact = this.changeEmergContact.bind(this);
    this.updateEmergContact = this.updateEmergContact.bind(this);
    this.deleteEmergContact = this.deleteEmergContact.bind(this);
  }

  componentDidMount() {
    this.updateWindowWidth();
    window.addEventListener('resize', this.updateWindowWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }

  updateWindowWidth() {
    this.setState({ windowWidth: window.innerWidth });
  }

  handleNextClick() {
    this.state.router.navigate("/accapp/3");
  }

  handlePageClick(link, alertText) {
    this.checkPageValidation((valid, msg) => {
      if (valid) {
        if (alertText !== "") {
          alert(alertText);
        } else {
          this.state.router.navigate(link);
        }
      } else {
        this.setState({
          alertMessage: msg
        });
      }
    });
  }

  checkPageValidation(callback) {
    /* TODO: Add functionality to handle validation */
    callback(true, "");
  }

  addContactMethod() {
    let contactMethods = this.state.contactMethods;
    contactMethods.push({ type: "", contactNumberOrAccount: "" });
    this.setState({ contactMethods });
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const contactMethods = reorder(
      this.state.contactMethods,
      result.source.index,
      result.destination.index
    );

    this.setState({ contactMethods });
  }

  getItemContent(index, method) {
    return(
      <div className="draggable-contact">
        <GrDrag className="drag-handle"/>
        <select 
          className="drag-select" 
          value={method.type}
          onChange={(e) => {
            this.onMethodSelectChange(index, e);
          }}
        >
          <option key="method-opt--1" value=""></option>
          {AccApplicationConstants.CONTACT_METHOD_TYPES.map((method, index) => {
            return(<option key={`method-opt-${index}`} value={method.value}>{method.label}</option>);
          })}
        </select>
        <input 
          type="text" 
          className="drag-input" 
          value={method.contactNumberOrAccount}
          onChange={(e) => {
            this.onMethodInputChange(index, e);
          }}
        />
        <BsXLg
          className="drag-delete"
          onClick={() => {
            this.onMethodDeleteClick(index);
          }}
        />
      </div>
    );
  }

  onMethodSelectChange(index, e) {
    if (index >=0 && index < this.state.contactMethods.length) {
      let contactMethods = JSON.parse(JSON.stringify(this.state.contactMethods));
      contactMethods[index].type = e.target.value;
      this.setState({ contactMethods });
    }
  }

  onMethodInputChange(index, e) {
    if (index >=0 && index < this.state.contactMethods.length) {
      let contactMethods = JSON.parse(JSON.stringify(this.state.contactMethods));
      contactMethods[index].contactNumberOrAccount = e.target.value;
      this.setState({ contactMethods });
    }
  }

  onMethodDeleteClick(index) {
    if (index >=0 && index < this.state.contactMethods.length) {
      let contactMethods = JSON.parse(JSON.stringify(this.state.contactMethods));
      contactMethods.splice(index, 1);
      this.setState({ contactMethods });
    }
  }

  changeEmergContact(index) {
    if (index === -1) {
      this.setState({
        emergContactIndex: index,
        emergContactName: "",
        emergContactAddress1: "",
        emergContactAddress2: "",
        emergContactLocality: "",
        emergContactState: "",
        emergContactPostcode: "",
        emergContactPhone: "",
        emergContainerClass: "emerg-contact-input-block invisible"
      });
    } else if (index < this.context.emergencyContacts.length) {
      let emergContact = this.context.emergencyContacts[index];
      this.setState({ 
        emergContactIndex: index, 
        emergContactName: emergContact.name,
        emergContactAddress1: emergContact.address1,
        emergContactAddress2: emergContact.address2,
        emergContactLocality: emergContact.locality,
        emergContactState: emergContact.state,
        emergContactPostcode: emergContact.postcode,
        emergContactPhone: emergContact.phone,
        emergContainerClass: "emerg-contact-input-block"
      });
    }
  }

  addEmergContact() {
    let emergencyContact = {
      id: 0,
      name: "",
      address1: "",
      address2: "",
      locality: "",
      state: "",
      postcode: "",
      phone: ""
    };
    let emergencyContacts = JSON.parse(JSON.stringify(this.context.emergencyContacts));
    emergencyContacts.push(emergencyContact);
    this.context.setEmergencyContacts(emergencyContacts);
    this.setState({
      emergContactIndex: emergencyContacts.length - 1,
      emergContactName: "",
      emergContactAddress1: "",
      emergContactAddress2: "",
      emergContactLocality: "",
      emergContactState: "",
      emergContactPostcode: "",
      emergContactPhone: "",
      emergContainerClass: "emerg-contact-input-block"
    });
  }

  deleteEmergContact() {
    if (this.state.emergContactIndex >= 0 && this.state.emergContactIndex < this.context.emergencyContacts.length) {
      let emergencyContacts = JSON.parse(JSON.stringify(this.context.emergencyContacts));
      emergencyContacts.splice(this.state.emergContactIndex, 1);
      this.context.setEmergencyContacts(emergencyContacts);
      this.setState({
        emergContactIndex: -1,
        emergContactName: "",
        emergContactAddress1: "",
        emergContactAddress2: "",
        emergContactLocality: "",
        emergContactState: "",
        emergContactPostcode: "",
        emergContactPhone: "",
        emergContainerClass: "emerg-contact-input-block invisible"
      });
    }
  }

  updateEmergContact(key, value) {
    if (this.state.emergContactIndex > -1 && this.state.emergContactIndex < this.context.emergencyContacts.length) {
      let state = this.state;
      state[key] = value;
      this.setState(state, () => {
        let emergencyContacts = JSON.parse(JSON.stringify(this.context.emergencyContacts));
        emergencyContacts[this.state.emergContactIndex] = {
          name: this.state.emergContactName,
          address1: this.state.emergContactAddress1,
          address2: this.state.emergContactAddress2,
          locality: this.state.emergContactLocality,
          state: this.state.emergContactState,
          postcode: this.state.emergContactPostcode,
          phone: this.state.emergContactPhone
        }
        this.context.setEmergencyContacts(emergencyContacts);
      });
    }
}

  render() {
    let { windowWidth, pagination, current } = this.state;
    const isMobile = (windowWidth < 768);
    return (
      <div className="accapp-page-container app-contacts-page row">
        {(!isMobile) && (
            <LogoLeftColumn link="/" mediaDisplay={false}/>
        )}
        <div className={isMobile ? "accapp-centre" : "accapp-centre col-8"}>
          <CustomPagination pages={pagination} current={current} callback={(l,a) => this.handlePageClick(l,a)}/>
          <h1 className="nw-heading">Contacts</h1>
          <div className="accapp-section">
            <p className="nw-title">As we may need to contact you urgently, please list as many ways as possible of getting in touch with you.</p>
            <p className="nw-title">Rank them in order of preference from most to least preferred.</p>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {this.state.contactMethods.map((method, index) => (
                      <Draggable key={`draggable${index}`} draggableId={`draggable${index}`} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            {this.getItemContent(index, method)}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <Button
              variant="outline-dark"
              className="nw-submit-button"
              onClick={this.addContactMethod} 
            >
              Add Contact Method
            </Button>
          </div>
          <div className="accapp-section">
            <p className="nw-title">If you are able, please provide up to 3 local contacts for friends or family that you can stay with while emergency accommodation is being found for you.</p>
            <div className="emerg-contact-wrapper">
              {this.context.emergencyContacts.map((contact, index) => (
                <ToggleButton
                  key={`emerg-contact-${index}`}
                  type="radio"
                  variant="outline-dark"
                  className="emerg-contact-button"
                  checked={this.state.emergContactIndex === index}
                  onClick={(e) => {
                    this.changeEmergContact(index);
                  }}
                >
                  {`Contact ${index + 1}`}
                </ToggleButton>
              ))}
              <Button
                variant="outline-dark"
                className="emerg-contact-add-button"
                onClick={(e) => {
                  this.addEmergContact();
                }}
              >
                Add
              </Button>
              <Button
                variant="outline-dark"
                className="emerg-contact-del-button"
                disabled={this.state.emergContactIndex > -1 ? false : true}
                onClick={(e) => {
                  this.deleteEmergContact();
                }}
              >
                Delete
              </Button>
            </div>
            <div className={this.state.emergContainerClass}>
              <div className="emerg-contact-input-wrapper">
                <label htmlFor="emergContactName" className="emerg-contact-label nw-inline-title">
                  Name
                </label>
                <Form.Control
                  id="emergContactName"
                  className="emerg-contact-input"
                  value={this.state.emergContactName}
                  onChange={(e) => {
                    this.emergencyContactUpdate("emergContactName", e.target.value);
                  }}
                />
              </div>
              <div className="emerg-contact-input-wrapper">
                <label htmlFor="emergContactAddress1" className="emerg-contact-label nw-inline-title">
                  Address
                </label>
                <Form.Control
                  id="emergContactAddress1"
                  className="emerg-contact-input"
                  value={this.state.emergContactAddress1}
                  onChange={(e) => {
                    this.emergencyContactUpdate("emergContactAddress1", e.target.value);
                  }}
                />
              </div>
              <div className="emerg-contact-input-wrapper">
                <div className="emerg-contact-label"></div>
                <Form.Control
                  className="emerg-contact-input"
                  value={this.state.emergContactAddress2}
                  aria-label="Address Line 2"
                  onChange={(e) => {
                    this.emergencyContactUpdate("emergContactAddress1", e.target.value);
                  }}
                />
              </div>
              <div className="emerg-contact-input-wrapper">
                <div className="emerg-contact-label-space"></div>
                <Form.Control
                  className="emerg-contact-locality"
                  value={this.state.emergContactLocality}
                  onChange={(e) => {
                    this.emergencyContactUpdate("emergContactLocality", e.target.value);
                  }}
                />
                <Form.Select 
                  className="emerg-contact-state"
                  value={this.state.emergContactState}
                  onChange={(e) => {
                    this.emergencyContactUpdate("emergContactState", e.target.value);
                  }}
                  aria-label="State" 
                >
                  <option></option>
                  <option>ACT</option>
                  <option>NSW</option>
                  <option>NT</option>
                  <option>QLD</option>
                  <option>SA</option>
                  <option>TAS</option>
                  <option>VIC</option>
                  <option>WA</option>
                </Form.Select>
                <Form.Control
                  className="emerg-contact-postcode"
                  value={this.state.emergContactPostcode}
                  onChange={(e) => {
                    this.emergencyContactUpdate("emergContactPostcode", e.target.value);
                  }}
                />
              </div>
              <div className="emerg-contact-input-wrapper">
                <label htmlFor="emergContactPhone" className="emerg-contact-label nw-inline-title">
                  Phone
                </label>
                <Form.Control
                  id="emergContactPhone"
                  className="emerg-contact-input"
                  value={this.state.emergContactPhone}
                  onChange={(e) => {
                    this.emergencyContactUpdate("emergContactPhone", e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <Button 
            variant="outline-dark" 
            className="nw-submit-button accapp-bottom-button" 
            aria-label="Go to next page"
            onClick={this.handleNextClick}
          >
            Next &gt;&gt;
          </Button>
        </div>
        {(!isMobile) && (
            <div className="accapp-right col-2"></div>
        )}
      </div>
    );
  }
}

export default withRouter(AccAppContactsPage);