import React from 'react';
import PropTypes from 'prop-types';

import Logo from '../../assets/images/bird-house-logo.svg';
import MapMediaDisplay from '../mapMediaDisplay/MapMediaDisplay';
import { withRouter } from '../../components/withRouter/withRouter.tsx';

import './LogoLeftColumn.css';

class LogoLeftColumn extends React.Component {
    static propTypes = {
        link: PropTypes.string,
        mediaDisplay: PropTypes.bool
    };

    constructor(props) {
        super(props);
        this.state = {
            mediaDisplay: props.mediaDisplay,
            link: props.link,
        }
    }

    render() {
        return (
            <div className="logo-column col-2">
                <a href={this.state.link}><img src={Logo} alt="Logo" className="logo"/></a>
                { this.state.mediaDisplay && (
                    <MapMediaDisplay className="media-display"/>
                )}
            </div>
        );
    }
}

export default withRouter(LogoLeftColumn);
