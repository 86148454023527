export const ACCOMMODATION_REASONS = [
    { label: "I was ordered to evacuate by emergency services", value: "evacuation order" },
    { label: "I voluntarily left where I live due to a \"Watch and Act\" alert", value: "watch and act" },
    { label: "Where I live is not accessible / habitable due to flood or fire", value: "not habitable / accessible" },
    { label: "Other", value: "other" }
];

export const SPECIAL_NEEDS = [
    { label: "One of us has a mobility issue and cannot move easily without assistance", field: "mobilityIssues" },
    { label: "One of us has difficulty with English or reading and needs assistance", field: "englishDifficulty" },
    { label: "One of us has experienced domestic violence so our location cannot be divulged", field: "domesticViolenceExperience" }
];

export const CLIENT_TYPES = [
    { label: "One of us resides locally in housing provided by the NSW Dept of Communities & Justice", field: "dcjHousingResident" },
    { label: "One of us is a client of Southern Cross Community Housing", field: "scchClient" },
    { label: "One of us lives in an aged care facility or group home in the local area", field: "agedCareResident" }
];

export const APP_ACCOMMODATION_TYPES = [
    { 
        label: "Entire Property", 
        value: "entire property", 
        class: "col-6 accapp-accom-button-left-wrapper",
        buttonClass: "accapp-accom-button" 
    },
    { 
        label: "Private Room/s", 
        value: "private rooms", 
        class: "col-6 accapp-accom-button-wrapper",
        buttonClass: "accapp-accom-button" 
    },
    { 
        label: "Granny Flat", 
        value: "granny flat", 
        class: "col-6 accapp-accom-button-left-wrapper",
        buttonClass: "accapp-accom-button" 
    },
    { 
        label: "Tiny Home", 
        value: "tiny home", 
        class: "col-6 accapp-accom-button-wrapper",
        buttonClass: "accapp-accom-button" 
    },
    { 
        label: "Caravan or Cabin", 
        value: "caravan", 
        class: "col-6 accapp-accom-button-left-wrapper",
        buttonClass: "accapp-accom-button" 
    },
    { 
        label: "Garage or Shed", 
        value: "shed", 
        class: "col-6 accapp-accom-button-wrapper",
        buttonClass: "accapp-accom-button" 
    },
    { 
        label: "Empty Shop / Office Space", 
        value: "shop / office", 
        class: "accapp-accom-button-wrapper",
        buttonClass: "accapp-accom-button" 
    },
    { 
        label: "Camping Area with Power & Amenities", 
        value: "powered camping", 
        class: "accapp-accom-button-wrapper",
        buttonClass: "accapp-accom-button" 
    },
    { 
        label: "Camping Area without Power", 
        value: "unpowered camping", 
        class: "accapp-accom-button-wrapper",
        buttonClass: "accapp-accom-button" 
    },
];

export const CONTACT_METHOD_TYPES = [
    { label: "Mobile", value: "mobile" },
    { label: "Email", value: "email" },
    { label: "Facebook", value: "facebook" },
    { label: "Instagram", value: "instagram" },
    { label: "Twitter", value: "twitter" },
    { label: "LinkedIn", value: "linkedin" },
    { label: "Airbnb", value: "airbnb" },
    { label: "Family Contact No.", value: "family number" },
    { label: "Friend Contact No.", value: "friend number" },
];
