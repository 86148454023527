import React from 'react';
import { Navigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Form from 'react-bootstrap/Form';

import { withRouter } from '../../components/withRouter/withRouter.tsx';
import LogoLeftColumn from '../../components/logoLeftColumn/LogoLeftColumn.js';
import CustomPagination from '../../components/customPagination/CustomPagination';
import GlobalContext from '../../components/context/GlobalContext';
import * as UIConstants from '../../constants/UIConstants';
import * as ValidationConstants from '../../constants/ValidationConstants';

import '../../styles/surveys.css';
import './SurveySecondPage.css';

class SurveySecondPage extends React.Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    this.state = {
      pagination : [
        { text: "1", link: "/survey/1", alert: "", cbflag: true, active: true }, 
        { text: "2", link: "/survey/2", alert: "", cbflag: true, active: true }, 
        { text: "3", link: "/survey/3", alert: "", cbflag: true, active: true }
      ],
      current : "2",
      validation: {
        shelterSatisfaction: ValidationConstants.UNCHECKED,
        shelterRepeat: ValidationConstants.UNCHECKED
      },
      alertMessage: "",
      windowWidth: window.innerWidth,
      router: props.router
    }
    this.handleNextClick = this.handleNextClick.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.checkPageValidation = this.checkPageValidation.bind(this);
    this.updateValidationType = this.updateValidationType.bind(this);
    this.updateValidation = this.updateValidation.bind(this);
    this.updateWindowWidth = this.updateWindowWidth.bind(this);
  }

  componentDidUpdate() {
    if (this.state.alertMessage !== "") {
      var alertmsg = this.state.alertMessage;
      this.setState({ alertMessage: ""}, () =>
        setTimeout((msg) => alert(msg), 250, alertmsg)
      );
    }
  }

  componentDidMount() {
    this.updateWindowWidth();
    window.addEventListener('resize', this.updateWindowWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }

  handleNextClick() {
    this.checkPageValidation((valid, msg) => {
      if (valid) {
        this.state.router.navigate("/survey/3");
      } else {
        this.setState({
          alertMessage: msg
        });
      }
    });
  }

  handlePageClick(link, alertText) {
    this.checkPageValidation((valid, msg) => {
      if (valid) {
        if (alertText !== "") {
          alert(alertText);
        } else {
            this.state.router.navigate(link);
        }
      } else {
        this.setState({
          alertMessage: msg
        });
      }
    });
  }

  checkPageValidation(callback) {
    this.updateValidation(() => {
      let v = this.state.validation;
      let result = ValidationConstants.UNCHECKED;
      if (v.shelterSatisfaction === ValidationConstants.VALID && v.shelterRepeat === ValidationConstants.VALID) {
        result = ValidationConstants.VALID;
      } else if (v.shelterSatisfaction === ValidationConstants.INCOMPLETE || v.shelterRepeat === ValidationConstants.INCOMPLETE) {
        result = ValidationConstants.INCOMPLETE;
      }
      switch (result) {
        case ValidationConstants.VALID:
          callback(true, "");
          break;
        case ValidationConstants.UNCHECKED:
          callback(false, "");
          break;
        case ValidationConstants.INVALID:
          callback(false, UIConstants.SURVEY_PAGE_ERRORS);
          break;
        case ValidationConstants.INCOMPLETE:
          callback(false, UIConstants.SURVEY_PAGE_INCOMPLETE);
          break;
        default:
      }
    });
  }

  updateValidationType(type, set) {
    let s = this.context.survey;
    let value = ValidationConstants.UNCHECKED;
    switch (type) {
      case "shelterSatisfaction":
        value = (s.shelterSatisfaction === "") ? ValidationConstants.INCOMPLETE : ValidationConstants.VALID;
        break;
      case "shelterRepeat":
        value = (s.shelterRepeat === "") ? ValidationConstants.INCOMPLETE : ValidationConstants.VALID;
        break;
      default:
    }
    if (set) {
      let validation = this.state.validation;
      validation[type] = value;
      this.setState({ validation });
    } else {
      return value;
    }
  }

  updateValidation(callback) {
    let validation = this.state.validation;
    validation.shelterSatisfaction = this.updateValidationType("shelterSatisfaction", false);
    validation.shelterRepeat = this.updateValidationType("shelterRepeat", false);
    this.setState({ validation }, callback());
  }

  updateWindowWidth() {
    this.setState({ windowWidth: window.innerWidth });
  }

  render() {
    if (this.context.survey.id !== 0) {
      return (<Navigate to={"/survey/complete"} replace={true}/>);
    } else {
      let { pagination, current, validation, windowWidth } = this.state;
      const isMobile = (windowWidth < 768);
      let shelterSatisfactionLabel = UIConstants.SHELTER_SATISFACTION_LABEL_GENERIC;
      let shelterRepeatLabel = UIConstants.SHELTER_REPEAT_LABEL_GENERIC;
      if (this.context.survey.floodEvacuation === "yes") {
        shelterSatisfactionLabel = UIConstants.SHELTER_SATISFACTION_LABEL_FLOOD;
        shelterRepeatLabel = UIConstants.SHELTER_REPEAT_LABEL_FLOOD;
      } else if (this.context.survey.fireEvacuation === "yes") {
        shelterSatisfactionLabel = UIConstants.SHELTER_SATISFACTION_LABEL_FIRE;
        shelterRepeatLabel = UIConstants.SHELTER_REPEAT_LABEL_FIRE;
      }
      return (
        <div className="surveypage-container survey-second-page row">
          {(!isMobile) && (
            <LogoLeftColumn link="/" mediaDisplay={false}/>
          )}
          <div className={isMobile ? "survey-centre" : "survey-centre col-8"}>
            <CustomPagination pages={pagination} current={current} callback={(l,a) => this.handlePageClick(l,a)}/>
            <h1 className="nw-heading">Tell Us About Your Experience</h1>
            <div className="survey-yes-no-block">
              <div className={validation.shelterSatisfaction === ValidationConstants.INVALID || validation.shelterSatisfaction === ValidationConstants.INCOMPLETE ? "nw-title invalid" : "nw-title"}>
                {shelterSatisfactionLabel}
              </div>
              <ButtonGroup aria-label="Satisfied with shelter - yes or no">
                <ToggleButton
                  className="nw-toggle-button survey-yes-no-button"
                  type="radio"
                  variant={validation.shelterSatisfaction === ValidationConstants.INVALID || validation.shelterSatisfaction === ValidationConstants.INCOMPLETE ? "outline-danger" : "outline-dark"}
                  checked={this.context.survey.shelterSatisfaction === "yes"}
                  onClick={(e) => {
                    this.context.setSurveyValues([{key: "shelterSatisfaction", value: "yes"}]);
                    if (validation.shelterSatisfaction === ValidationConstants.INVALID || validation.shelterSatisfaction === ValidationConstants.INCOMPLETE) {
                      this.updateValidationType("shelterSatisfaction", true);
                    }
                  }}
                >
                  Yes
                </ToggleButton>
                <ToggleButton
                  className="nw-toggle-button survey-yes-no-button"
                  type="radio"
                  variant={validation.shelterSatisfaction === ValidationConstants.INVALID || validation.shelterSatisfaction === ValidationConstants.INCOMPLETE ? "outline-danger" : "outline-dark"}
                  checked={this.context.survey.shelterSatisfaction === "no"}
                  onClick={(e) => {
                    this.context.setSurveyValues([{key: "shelterSatisfaction", value: "no"}]);
                    if (validation.shelterSatisfaction === ValidationConstants.INVALID || validation.shelterSatisfaction === ValidationConstants.INCOMPLETE) {
                      this.updateValidationType("shelterSatisfaction", true);
                    }
                  }}
                >
                  No
                </ToggleButton>
              </ButtonGroup>
            </div>
            <h2 className="nw-title">Why or why not?</h2>
            <Form.Control 
              as="textarea"
              rows={3}
              value={this.context.survey.shelterSatisfactionReason}
              className="nw-input survey-textarea survey-group-end-2"
              aria-label="Enter reason for shelter satisfaction"
              onChange={(e) => this.context.setSurveyValues([{key: "shelterSatisfactionReason", value: e.currentTarget.value}])}
            />
            <div className="survey-yes-no-block">
              <div className={validation.shelterRepeat === ValidationConstants.INVALID || validation.shelterRepeat === ValidationConstants.INCOMPLETE ? "nw-title invalid" : "nw-title"}>
                {shelterRepeatLabel}
              </div>
              <ButtonGroup aria-label="Shelter same way again during flood - yes or no">
                <ToggleButton
                  className="nw-toggle-button survey-yes-no-button"
                  type="radio"
                  variant={validation.shelterRepeat === ValidationConstants.INVALID || validation.shelterRepeat === ValidationConstants.INCOMPLETE ? "outline-danger" : "outline-dark"}
                  checked={this.context.survey.shelterRepeat === "yes"}
                  onClick={(e) => {
                    this.context.setSurveyValues([{key: "shelterRepeat", value: "yes"}]);
                    if (validation.shelterRepeat === ValidationConstants.INVALID || validation.shelterRepeat === ValidationConstants.INCOMPLETE) {
                      this.updateValidationType("shelterRepeat", true);
                    }
                  }}
                >
                  Yes
                </ToggleButton>
                <ToggleButton
                  className="nw-toggle-button survey-yes-no-button"
                  type="radio"
                  variant={validation.shelterRepeat === ValidationConstants.INVALID || validation.shelterRepeat === ValidationConstants.INCOMPLETE ? "outline-danger" : "outline-dark"}
                  checked={this.context.survey.shelterRepeat === "no"}
                  onClick={(e) => {
                    this.context.setSurveyValues([{key: "shelterRepeat", value: "no"}])
                    if (validation.shelterRepeat === ValidationConstants.INVALID || validation.shelterRepeat === ValidationConstants.INCOMPLETE) {
                      this.updateValidationType("shelterRepeat", true);
                    }
                  }}
                >
                  No
                </ToggleButton>
              </ButtonGroup>
            </div>
            <h2 className="nw-title">Why or why not?</h2>
            <Form.Control 
              as="textarea"
              rows={3}
              value={this.context.survey.shelterRepeatReason}
              className="nw-input survey-textarea survey-group-end"
              aria-label="Enter reason for choosing flood shelter again"
              onChange={(e) => this.context.setSurveyValues([{key: "shelterRepeatReason", value: e.currentTarget.value}])}
            />
            <Button variant="outline-dark" className="nw-submit-button nw-bottom-button" onClick={this.handleNextClick}>Next &gt;&gt;</Button>
          </div>
          {(!isMobile) && (
            <div className="survey-right col-2"></div>
          )}
        </div>
      );
    }
  }
}

export default withRouter(SurveySecondPage);