import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { withRouter } from '../../components/withRouter/withRouter.tsx';
import LogoLeftColumn from '../../components/logoLeftColumn/LogoLeftColumn.js';
import CustomPagination from '../../components/customPagination/CustomPagination';
import GlobalContext from '../../components/context/GlobalContext';
import DataService from '../../services/DataService.js';

import '../../styles/offerings.css';
import './OfferRoomsPage.css';

class OfferRoomsPage extends React.Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    this.state = {
      pagination: [
        { text: "1", link: "/offering/1", alert: "", cbflag: true, active: true }, 
        { text: "2", link: "/offering/2", alert: "", cbflag: true, active: true }, 
        { text: "3", link: "/offering/3", alert: "", cbflag: true, active: true },
        { text: "4", link: "/offering/4", alert: "", cbflag: true, active: true },
        { text: "5", link: "/offering/5", alert: "", cbflag: true, active: true },
        { text: "6", link: "/offering/6", alert: "", cbflag: true, active: true },
      ],
      current: "6",
      windowWidth: window.innerWidth,
      router: props.router,
      roomIndex: -1,
      roomId: 0,
      roomName: "",
      roomDescription: "",
      roomMaximumOccupancy: 0,
      roomReimbursementAmount: 0,
      roomReimbursementPeriod: "",
      roomContainerClass: "offer-room-container invisible"
    };
    this.updateWindowWidth = this.updateWindowWidth.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.checkPageValidation = this.checkPageValidation.bind(this);
    this.changeRoom = this.changeRoom.bind(this);
    this.addRoom = this.addRoom.bind(this);
    this.deleteRoom = this.deleteRoom.bind(this);
    this.updateRoom = this.updateRoom.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
  }

  componentDidMount() {
    this.updateWindowWidth();
    window.addEventListener('resize', this.updateWindowWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }

  updateWindowWidth() {
    this.setState({ windowWidth: window.innerWidth });
  }

  handlePageClick(link, alertText) {
    this.checkPageValidation((valid, msg) => {
      if (valid) {
        if (alertText !== "") {
          alert(alertText);
        } else {
          this.state.router.navigate(link);
        }
      } else {
        this.setState({
          alertMessage: msg
        });
      }
    });
  }

  checkPageValidation(callback) {
    /* TODO: Add functionality to handle validation */
    callback(true, "");
  }

  changeRoom(e) {
    const index = parseInt(e.target.value);
    if (index === -1) {
      this.setState({
        roomIndex: -1,
        roomName: "",
        roomDescription: "",
        roomMaximumOccupancy: 0,
        roomReimbursementAmount: 0,
        roomReimbursementPeriod: "",
        roomContainerClass: "offer-room-container invisible"
      });
    } else if (index < this.context.rooms.length) {
      let room = this.context.rooms[index];                 
      this.setState({
        roomIndex: index,
        roomName: room.name,
        roomDescription: room.description,
        roomMaximumOccupancy: room.maximumOccupancy,
        roomReimbursementAmount: room.reimbursementAmount,
        roomReimbursementPeriod: room.reimbursementPeriod,
        roomContainerClass: "offer-room-container"
      });
    }
  }

  addRoom() {
    let roomName = prompt("Enter a name for the room");
    if (roomName !== "") {
      let room = {
        id: 0,
        name: roomName,
        description: "",
        maximumOccupancy: 0,
        reimbursementAmount: 0,
        reimbursementPeriod: ""
      };
      let rooms = JSON.parse(JSON.stringify(this.context.rooms));
      rooms.push(room);
      this.context.setRooms(rooms);
      this.setState({
        roomIndex: rooms.length - 1,
        roomName: roomName,
        roomDescription: "",
        roomMaximumOccupancy: 0,
        roomReimbursementAmount: 0,
        roomReimbursementPeriod: "",
        roomContainerClass: "offer-room-container"
      });
    }
  }

  deleteRoom() {
    if (this.state.roomIndex >= 0 && this.state.roomIndex < this.context.rooms.length) {
      let rooms = JSON.parse(JSON.stringify(this.context.rooms));
      rooms.splice(this.state.roomIndex, 1);
      this.context.setRooms(rooms);
      this.setState({
        roomIndex: -1,
        roomId: 0,
        roomName: "",
        roomDescription: "",
        roomMaximumOccupancy: 0,
        roomReimbursementAmount: 0,
        roomReimbursementPeriod: "",
        roomContainerClass: "offer-room-container invisible"
      });
    }
  }

  updateRoom() {
    if (this.state.roomIndex > -1 && this.state.roomIndex < this.context.rooms.length) {
      let rooms = JSON.parse(JSON.stringify(this.context.rooms));
      rooms[this.state.roomIndex] = {
        id: this.state.roomId,
        name: this.state.roomName,
        description: this.state.roomDescription,
        maximumOccupancy: this.state.roomMaximumOccupancy,
        reimbursementAmount: this.state.roomReimbursementAmount,
        reimbursementPeriod: this.state.roomReimbursementPeriod
      }
      this.context.setRooms(rooms);
    }
  }

  handleSaveClick() {
    DataService.saveOfferData(this.context)
    .then(response => {
      if (response.data) {
        if (response.data.owner) {
          this.context.setOwnerValues([{ key: "id", value: response.data.owner.id}]);
        }
        if (response.data.accommodation) {
          this.context.setAccommodationValues([{ key: "id", value: response.data.owner.id}]);
        }
        if (response.data.accomAmenities) {
          this.context.setAccomAmenities(response.data.accomAmenities);
        }
        if (response.data.rooms) {
          this.context.setRooms(response.data.rooms);
        }
      }
      this.state.router.navigate("/");
    })
    .catch(error => {
      console.error('Error occurred saving survey', error);
      alert("Unable to save data : " + error);
    });
  }

  render() {
    let { current, pagination, windowWidth } = this.state;
    const isMobile = (windowWidth < 768);
    return (
      <div className="offer-page-container offer-rooms-page row">
        {(!isMobile) && (
          <LogoLeftColumn link="/" mediaDisplay={false}/>
        )}
        <div className={isMobile ? "offer-centre" : "offer-centre col-8"}>
          <CustomPagination pages={pagination} current={current} callback={(l,a) => this.handlePageClick(l,a)}/>
          <h1 className="nw-heading offer-heading">Tell Us About The Rooms</h1>
          <div className="offer-section offer-room-select-section">
            <div className="offer-room-select-row">
              <Form.Select 
                className="offer-select offer-room-select"
                value={this.state.roomIndex}
                aria-label="Select room"
                onChange={(e) => {
                  this.changeRoom(e);
                }} 
              >
                <option value="-1"></option>
                {this.context.rooms.map((room, index) => (
                  <option value={index}>{room.name}</option>
                ))}
              </Form.Select>
              <Button
                variant="outline-dark"
                className="offer-room-addedit-button"
                onClick={(e) => {
                  this.addRoom();
                }}
              >
                Add
              </Button>
              <Button
                variant="outline-dark"
                className="offer-room-addedit-button"
                disabled={this.state.roomIndex > -1 ? false : true}
                onClick={(e) => {
                  this.deleteRoom();
                }}
              >
                Delete
              </Button>
            </div>
          </div>
          <div className={this.state.roomContainerClass}>
            <div className="offer-section">
              <div className="offer-room-name-row">
                <h2 className="nw-inline-title offer-room-name-label">Room name</h2>
                <Form.Control 
                  type="text"
                  className="offer-input offer-room-name-input"
                  defaultValue={this.state.roomName}
                  aria-label="Enter room name"
                  onChange={(e) => {
                    this.setState({ roomName: e.target.value }, () => { this.updateRoom(); });
                  }}
                />
              </div>
            </div>
            <div className="offer-section">
              <h2 className="nw-title">Room description (include details about beds, amenities, etc)</h2>
              <Form.Control 
                as="textarea"
                rows={3}
                className="nw-input"
                value={this.state.roomDescription}
                aria-label="Enter room description"
                onChange={(e) => {
                  this.setState({ roomDescription: e.target.value }, () => { this.updateRoom(); });
                }}
              />
            </div>
            <div className="offer-section">
              <div className="offer-room-people-row">
                <h2 className="nw-inline-title offer-room-people-label">How many people can this room accommodate?</h2>
                <Form.Control 
                  type="text"
                  className="offer-input offer-room-people-input"
                  value={this.state.roomMaximumOccupancy}
                  aria-label="Enter number of people room can accommodate"
                  onChange={(e) => {
                    this.setState({ roomMaximumOccupancy: e.target.value }, () => { this.updateRoom(); });
                  }}
                />
              </div>
            </div>
            <div className="offer-section">
              <h2 className="nw-title">What is the maximum you would charge for this room during an emergency?</h2>
              <div className="offer-room-cost-row">
                <h2 className="nw-inline-title">$</h2>
                <Form.Control 
                  type="text"
                  className="offer-input"
                  value={this.state.roomReimbursementAmount}
                  aria-label="Maximum charge during an emergency"
                  onChange={(e) => {
                    this.setState({ roomReimbursementAmount: e.target.value }, () => { this.updateRoom(); });
                  }}
                />
                <h2 className="nw-inline-title">per</h2>
                <Form.Select
                  className="offer-select"
                  value={this.state.roomReimbursementPeriod} 
                  aria-label="Per day, week or month"
                  onChange={(e) => {
                    console.log("roomReimbursementPeriod", e.target.value);
                    this.setState({ roomReimbursementPeriod: e.target.value }, () => { this.updateRoom(); });
                  }}
                >
                  <option value=""></option>
                  <option value="day">Day</option>
                  <option value="week">Week</option>
                  <option value="month">Month</option>
                </Form.Select>
              </div>
            </div>
          </div>
          <div className="offer-button-row">
            <Button 
                variant="outline-dark" 
                className="nw-submit-button offer-bottom-button" 
                aria-label="Save and leave"
                onClick={this.handleSaveClick}
              >
                Save &amp; Finish
              </Button>
          </div>
        </div>
        {(!isMobile) && (
            <div className="offer-right col-2"></div>
        )}
      </div>
    );
  }
}

export default withRouter(OfferRoomsPage);