import React from 'react';
import { Navigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Form from 'react-bootstrap/Form';

import { withRouter } from '../../components/withRouter/withRouter.tsx';
import LogoLeftColumn from '../../components/logoLeftColumn/LogoLeftColumn.js';
import CustomPagination from '../../components/customPagination/CustomPagination';
import GlobalContext from '../../components/context/GlobalContext';
import * as SurveyConstants from '../../constants/SurveyConstants';
import * as UIConstants from '../../constants/UIConstants';
import * as ValidationConstants from '../../constants/ValidationConstants';

import '../../styles/surveys.css';
import './SurveyFirstPage.css';

class SurveyFirstPage extends React.Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    this.state = {
      pagination: [
        { text: "1", link: "/survey/1", alert: "", cbflag: true, active: true }, 
        { text: "2", link: "/survey/2", alert: "", cbflag: true, active: true }, 
        { text: "3", link: "/survey/3", alert: "", cbflag: true, active: true }
      ],
      current: "1",
      validation: {
        lga: ValidationConstants.UNCHECKED,
        evacuation: ValidationConstants.UNCHECKED,
        warning: ValidationConstants.UNCHECKED,
        shelter: ValidationConstants.UNCHECKED
      },
      alertMessage: "",
      windowWidth: window.innerWidth,
      labelsUpdated: false,
      warningLevelLabel: UIConstants.WARNING_LEVEL_LABEL_GENERIC,
      shelterLabel: UIConstants.SHELTER_LABEL_GENERIC,
      evacQuestionsCss: SurveyConstants.EVAC_LAYOUT_HIDE,
      evacBypassCss: SurveyConstants.EVAC_LAYOUT_HIDE,
      router: props.router
    }
    this.handleNextClick = this.handleNextClick.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.checkPageValidation = this.checkPageValidation.bind(this);
    this.updateValidationType = this.updateValidationType.bind(this);
    this.updateValidation = this.updateValidation.bind(this);
    this.updateWindowWidth = this.updateWindowWidth.bind(this);
    this.updateLabels = this.updateLabels.bind(this);
  }

  componentDidUpdate() {
    if (this.state.alertMessage !== "") {
      var alertmsg = this.state.alertMessage;
      this.setState({ alertMessage: ""}, () =>
        setTimeout((msg) => alert(msg), 250, alertmsg)
      );
    }
  }

  componentDidMount() {
    this.updateWindowWidth();
    window.addEventListener('resize', this.updateWindowWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }

  handleNextClick() {
    this.checkPageValidation((valid, msg) => {
      if (valid) {
        this.state.router.navigate(this.state.evacBypassCss === SurveyConstants.EVAC_LAYOUT_SHOW ? "/survey/3" : "/survey/2");
      } else {
        this.setState({
          alertMessage: msg
        });
      }
    });
  }

  handlePageClick(link, alertText) {
    this.checkPageValidation((valid, msg) => {
      if (valid) {
        if (alertText !== "") {
          alert(alertText);
        } else {
          this.state.router.navigate(link);
        }
      } else {
        this.setState({
          alertMessage: msg
        });
      }
    });
  }

  checkPageValidation(callback) {
    this.updateValidation(() => {
      let v = this.state.validation;
      let result = ValidationConstants.UNCHECKED;
      if (v.lga === ValidationConstants.VALID && v.evacuation === ValidationConstants.VALID && v.warning === ValidationConstants.VALID && v.shelter === ValidationConstants.VALID) {
        result = ValidationConstants.VALID;
      } else if (v.lga === ValidationConstants.INCOMPLETE || v.evacuation === ValidationConstants.INCOMPLETE || v.warning === ValidationConstants.INCOMPLETE || v.shelter === ValidationConstants.INCOMPLETE) {
        result = ValidationConstants.INCOMPLETE;
      }
      switch (result) {
        case ValidationConstants.VALID:
          callback(true, "");
          break;
        case ValidationConstants.UNCHECKED:
          callback(false, "");
          break;
        case ValidationConstants.INVALID:
          callback(false, UIConstants.SURVEY_PAGE_ERRORS);
          break;
        case ValidationConstants.INCOMPLETE:
          callback(false, UIConstants.SURVEY_PAGE_INCOMPLETE);
          break;
        default:
      }
    });
  }

  updateValidationType(type, set) {
    let s = this.context.survey;
    let value = ValidationConstants.UNCHECKED;
    switch (type) {
      case "lga":
        value = (s.lga === "") ? ValidationConstants.INCOMPLETE : ValidationConstants.VALID;
        break;
      case "evacuation":
        value = (s.evacuationReason === "") ? ValidationConstants.INCOMPLETE : ValidationConstants.VALID;
        break;
      case "warning":
        if (s.evacuationReason === SurveyConstants.NEVER_EVACUATED) {
          value = ValidationConstants.VALID;
        } else {
          value = (s.warningLevel === "") ? ValidationConstants.INCOMPLETE : ValidationConstants.VALID;
        }
        break;
      case "shelter":
        if (s.evacuationReason === SurveyConstants.NEVER_EVACUATED) {
          value = ValidationConstants.VALID;
        } else {
          value = (s.shelterOnEvacuation === "") ? ValidationConstants.INCOMPLETE : ValidationConstants.VALID;
        }
        break;
      default:
    }
    if (set) {
      let validation = this.state.validation;
      validation[type] = value;
      this.setState({ validation });
    } else {
      return value;
    }
  }

  updateValidation(callback) {
    let validation = this.state.validation;
    validation.lga = this.updateValidationType("lga", false);
    validation.evacuation = this.updateValidationType("evacuation", false);
    validation.warning = this.updateValidationType("warning", false);
    validation.shelter = this.updateValidationType("shelter", false);
    this.setState({ validation }, callback());
  }

  updateWindowWidth() {
    this.setState({ windowWidth: window.innerWidth });
  }

  updateLabels() {
    if (this.context.survey.fireEvacuation === "yes") {
      this.setState({
        labelsUpdated: true,
        warningLevelLabel: UIConstants.WARNING_LEVEL_LABEL_FIRE,
        shelterLabel: UIConstants.SHELTER_LABEL_FIRE
      });
    } else if (this.context.survey.floodEvacuation === "yes") {
      this.setState({
        labelsUpdated: true,
        warningLevelLabel: UIConstants.WARNING_LEVEL_LABEL_FLOOD,
        shelterLabel: UIConstants.SHELTER_LABEL_FLOOD
      });
    } else {
      this.setState({
        labelsUpdated: true,
        warningLevelLabel: UIConstants.WARNING_LEVEL_LABEL_GENERIC,
        shelterLabel: UIConstants.SHELTER_LABEL_GENERIC
      });
    }
  }

  render() {
    if (this.context.survey.id !== 0) {
      return (<Navigate to={"/survey/complete"} replace={true}/>);
    } else {
      let { pagination, current, validation, windowWidth, warningLevelLabel, shelterLabel, labelsUpdated, evacQuestionsCss, evacBypassCss } = this.state;
      if (!labelsUpdated) {
        if (this.context.survey.evacuationReason === SurveyConstants.FLOOD_EVACUATION) {
          warningLevelLabel = UIConstants.WARNING_LEVEL_LABEL_FLOOD;
          shelterLabel = UIConstants.SHELTER_LABEL_FLOOD;
        } else if (this.context.survey.fireEvacuation === SurveyConstants.FIRE_EVACUATION) {
          warningLevelLabel = UIConstants.WARNING_LEVEL_LABEL_FIRE;
          shelterLabel = UIConstants.SHELTER_LABEL_FIRE;
        }
      }
      if (this.context.survey.evacuationReason) {
        if (this.context.survey.evacuationReason === SurveyConstants.NEVER_EVACUATED) {
          evacQuestionsCss = SurveyConstants.EVAC_LAYOUT_HIDE;
          evacBypassCss = SurveyConstants.EVAC_LAYOUT_SHOW;
        } else {
          evacQuestionsCss = SurveyConstants.EVAC_LAYOUT_SHOW;
          evacBypassCss = SurveyConstants.EVAC_LAYOUT_HIDE;
        }
      } else {
        evacQuestionsCss = SurveyConstants.EVAC_LAYOUT_HIDE;
        evacBypassCss = SurveyConstants.EVAC_LAYOUT_HIDE;
      }
      const isMobile = (windowWidth < 768);
      return (
        <div className="surveypage-container survey-first-page row">
          {(!isMobile) && (
            <LogoLeftColumn link="/" mediaDisplay={false}/>
          )}
          <div className={isMobile ? "survey-centre" : "survey-centre col-8"}>
            <CustomPagination pages={pagination} current={current} callback={(l,a) => this.handlePageClick(l,a)}/>
            <h1 className="nw-heading">Tell Us About Your Experience</h1>

            <h2 className={validation.lga === ValidationConstants.INVALID || validation.lga === ValidationConstants.INCOMPLETE ? "nw-title invalid" : "nw-title"}>In which LGA do you live or own property? *</h2>
            <ButtonGroup className="nw-input-group" aria-label="Select LGA">
              {SurveyConstants.LGA_VALUES.map((lga, index) => (
                  <ToggleButton
                    key={`lga-${index}`}
                    className="nw-toggle-button"
                    type="radio"
                    variant={validation.lga === ValidationConstants.INVALID || validation.lga === ValidationConstants.INCOMPLETE ? "outline-danger" : "outline-dark"}
                    value={lga}
                    checked={this.context.survey.lga === lga}
                    onClick={(e) => {
                      this.context.setSurveyValues([{key: "lga", value: lga}]);
                      if (validation.lga === ValidationConstants.INVALID || validation.lga === ValidationConstants.INCOMPLETE) {
                        this.updateValidationType("lga", true);
                      }
                    }}
                  >
                    {lga}
                  </ToggleButton>
                ))}
            </ButtonGroup>

            <h2 className={validation.evacuation === ValidationConstants.INVALID || validation.evacuation === ValidationConstants.INCOMPLETE ? "nw-title invalid" : "nw-title"}>
              Have you had to leave your home or property any time in the last 3 years due to natural disasters? (Tell us about your most recent experience.) *
            </h2>
            {SurveyConstants.EVACUATION_REASONS.map((reason, index) => (
              <ButtonGroup key={`evac-reason-group-${index}`} className="survey-evac-button-group" aria-label={reason.label}>
                <ToggleButton
                  key={`evac-reason-${index}`}
                  className="nw-toggle-button survey-evac-button"
                  type="radio"
                  variant={validation.evacuation === ValidationConstants.INVALID || validation.evacuation === ValidationConstants.INCOMPLETE ? "outline-danger" : "outline-dark"}
                  value={reason.value}
                  checked={this.context.survey.evacuationReason === reason.value}
                  onClick={(e) => {
                    this.context.setSurveyValues([{key: "evacuationReason", value: reason.value}]);
                    let state = {};
                    if (validation.evacuation === ValidationConstants.INVALID || validation.evacuation === ValidationConstants.INCOMPLETE) {
                      state = this.updateValidationType("evacuation", true);
                    }
                    if (reason.value === SurveyConstants.NEVER_EVACUATED) {
                      state["evacQuestionsCss"] = SurveyConstants.EVAC_LAYOUT_HIDE;
                      state["evacBypassCss"] = SurveyConstants.EVAC_LAYOUT_SHOW;
                    } else {
                      state["evacQuestionsCss"] = SurveyConstants.EVAC_LAYOUT_SHOW;
                      state["evacBypassCss"] = SurveyConstants.EVAC_LAYOUT_HIDE;
                    }
                    if (state !== {}) {
                      this.setState(state);
                    }
                }}
                >
                  {reason.label}
                </ToggleButton>
              </ButtonGroup>
            ))}

            <div className="evac-followup-container">

              <div id="evac-followup-questions" className={evacQuestionsCss}>

                <h2 className={validation.warning === ValidationConstants.INVALID || validation.warning === ValidationConstants.INCOMPLETE ? "nw-title invalid" : "nw-title"}>
                  {warningLevelLabel}
                </h2>
                <ButtonGroup className="nw-input-group" aria-label="Select evacuation warning level">
                  {SurveyConstants.WARNING_LEVELS.map((option, index) => {
                    let checked = (option.value === this.context.survey.warningLevel);
                    return (
                      <ToggleButton 
                        key={`wrn-${index}`}
                        className={validation.warning === ValidationConstants.INVALID || validation.warning === ValidationConstants.INCOMPLETE ? "nw-toggle-button invalid" : "nw-toggle-button"}
                        type="radio"
                        variant={validation.warning === ValidationConstants.INVALID || validation.warning === ValidationConstants.INCOMPLETE ? "outline-danger" : "outline-dark"}
                        value={option.value}
                        checked={checked}
                        onClick={(e) => {
                          this.context.setSurveyValues([{key: "warningLevel", value: option.value}]);
                          if (validation.warning === ValidationConstants.INVALID || validation.warning === ValidationConstants.INCOMPLETE) {
                            this.updateValidationType("warning", true);
                          }
                        }}
                      >
                        {option.label}
                      </ToggleButton>
                    );
                  })}
                </ButtonGroup>

                <h2 className={validation.shelter === ValidationConstants.INVALID || validation.shelter === ValidationConstants.INCOMPLETE ? "nw-title invalid" : "nw-title"}>
                  {shelterLabel}
                </h2>
                <Form.Select className={validation.shelter === ValidationConstants.INVALID || validation.shelter === ValidationConstants.INCOMPLETE ? "nw-select shelter-select invalid" : "nw-select shelter-select"}
                  aria-label="Select shelter when evacuated" 
                  value={this.context.survey.shelterOnEvacuation}
                  onChange={(e) => {
                    let set = [{key: "shelterOnEvacuation", value: e.currentTarget.value}];
                    if (e.currentTarget.value !== "other") {
                      set.push({key: "shelterOnEvacuationOther", value: ""});
                    }
                    this.context.setSurveyValues(set);
                    if (validation.shelter === ValidationConstants.INVALID || validation.shelter === ValidationConstants.INCOMPLETE) {
                      this.updateValidationType("shelter", true);
                    }
                  }}
                >
                  {SurveyConstants.SHELTER_OPTIONS.map((option, index) => (
                      <option key={`shltr-${index}`} value={option.value}>{option.label}</option>
                  ))}
                </Form.Select>
                <div className="shelter-other">
                    <div className="shelter-other-title nw-inline-title">If Other :</div>
                    <div className="shelter-other-input">
                      <Form.Control 
                        as="textarea"
                        rows={2}
                        value={this.context.survey.shelterOnEvacuationOther}
                        className="nw-input"
                        aria-label="Enter other shelter description"
                        onChange={(e) => this.context.setSurveyValues([{key: "shelterOnEvacuationOther", value: e.currentTarget.value}])}
                      />
                    </div>
                </div>

              </div>

              <div id="evac-followup-bypass" className={evacBypassCss}>
                <h2 className="nw-title">
                  You do need not to answer any of the questions in Page 2 of the survey. Click on the next button below to go straight to Page 3.
                </h2>
              </div>

            </div>

            <Button 
              variant="outline-dark" 
              className="nw-submit-button nw-bottom-button" 
              aria-label="Go to next page"
              onClick={this.handleNextClick}
            >
              Next &gt;&gt;
            </Button>
          </div>
          {(!isMobile) && (
            <div className="survey-right col-2"></div>
          )}
        </div>
      );
    }
  }
}

export default withRouter(SurveyFirstPage);