export const LGA_VALUES = ["Shoalhaven", "Eurobodalla", "Bega Valley"];
export const FLOOD_EVACUATION = "flood";
export const FIRE_EVACUATION = "bushfire";
export const NEVER_EVACUATED = "never evacuated";
export const EVACUATION_REASONS = [
    { label: "Yes, because of floods", value: FLOOD_EVACUATION },
    { label: "Yes, because of bushfires", value: FIRE_EVACUATION },
    { label: "No, I have never evacuated", value: NEVER_EVACUATED },
];
export const WARNING_LEVELS = [
    { label: "Emergency", value: "emergency" },
    { label: "Watch And Act", value: "watch and act" },
    { label: "Advice", value: "advice" },
];
export const SHELTER_OPTIONS = [
    { label: "", value: ""},
    { label: "I Stayed with Friends or Family", value: "friends or family" },
    { label: "I Went to an Evacuation Centre", value: "evacuation centre" },
    { label: "I Booked & Paid for Accommodation at a Local Hotel, Motel, Caravan Park, etc", value: "local accommodation" },
    { label: "I Booked & Paid for Home Sharing Accommodation through Airbnb or similar", value: "home share" },
    { label: "I Booked & Paid for a Holiday House through an Online Platform (eg Stayz, Airbnb, etc)", value: "holiday house" },
    { label: "Other", value: "other" }
];
export const EVAC_LAYOUT_SHOW = "sv-evac-show";
export const EVAC_LAYOUT_HIDE = "sv-evac-hide";