import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { BsGoogle, BsFacebook, BsTwitter, BsInstagram, BsLinkedin } from 'react-icons/bs';
import validator from 'validator';

import { withRouter } from '../../components/withRouter/withRouter.tsx';
import LogoLeftColumn from '../../components/logoLeftColumn/LogoLeftColumn.js';
import GlobalContext from '../../components/context/GlobalContext';
import AuthService from '../../services/AuthService.js';
import DataService from '../../services/DataService.js';
import * as UIConstants from '../../constants/UIConstants';

import '../../styles/registerlogin.css';
import './AccAppLoginPage.css';

class AccAppLoginPage extends React.Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      windowWidth: window.innerWidth,
      router: props.router,
      loginError: ""
    };
    this.handleGoogleLogin = this.handleGoogleLogin.bind(this);
    this.handleFacebookLogin = this.handleFacebookLogin.bind(this);
    this.handleTwitterLogin = this.handleTwitterLogin.bind(this);
    this.handleInstagramLogin = this.handleInstagramLogin.bind(this);
    this.handleLinkedInLogin = this.handleLinkedInLogin.bind(this);
    this.handleEmailLogin = this.handleEmailLogin.bind(this);
    this.completeLogin = this.completeLogin.bind(this);
    this.displayLoginError = this.displayLoginError(this);
    this.updateWindowWidth = this.updateWindowWidth.bind(this);
  }

  componentDidMount() {
    this.updateWindowWidth();
    window.addEventListener('resize', this.updateWindowWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }

  updateWindowWidth() {
    this.setState({ windowWidth: window.innerWidth });
  }

  handleGoogleLogin() {
    AuthService.googleSignIn()
    .then(signInResponse => {
      this.completeLogin(signInResponse);
    })
    .catch(error => {
      this.displayLoginError(error);
    });
  }

  handleFacebookLogin() {
    AuthService.facebookSignIn()
    .then(signInResponse => {
      this.completeLogin(signInResponse);
    })
    .catch(error => {
      this.displayLoginError(error);
    });
  }

  handleTwitterLogin() {
    AuthService.twitterSignIn()
    .then(signInResponse => {
      this.completeLogin(signInResponse);
    })
    .catch(error => {
      this.displayLoginError(error);
    });
  }

  handleInstagramLogin() {
    AuthService.instagramSignIn()
    .then(signInResponse => {
      this.completeLogin(signInResponse);
    })
    .catch(error => {
      this.displayLoginError(error);
    });
  }

  handleLinkedInLogin() {
    AuthService.linkedinSignIn()
    .then(signInResponse => {
      this.completeLogin(signInResponse);
    })
    .catch(error => {
      this.displayLoginError(error);
    });
  }

  handleEmailLogin() {
    if (!validator.isEmail(this.state.email)) {
      this.setState({loginError: UIConstants.EMAIL_INVALID_ERROR});
    } else if (this.state.password === "") {
      this.setState({registerError: UIConstants.NO_PASSWORD_ERROR});
    } else {
      AuthService.localSignIn(this.state.email, this.state.password, true)
      .then(signInResponse => {
        this.completeLogin(signInResponse);
      })
      .catch( error => {
        this.displayLoginError(error);
      });
    }
  }

  completeLogin(response) {
    console.log(response);
    const userId = parseInt(response.userid);
    const name = response.name;
    this.context.setUserValues([
      { key: "userId", value: userId },
      { key: "username", value: name }
    ]);
    this.context.setAccomApplicantValues([
      { key: "userId", value: userId },
      { key: "name", value: name },
    ]);
    if (response.data.accomApplicant) {
        this.context.setAccomApplicantValues(DataService.createKeyValues(response.data.accomApplicant));
    }
    if (response.data.accomApplication) {
        this.context.setAccomApplicationValues(DataService.createKeyValues(response.data.accomApplication));
    }
    if (response.data.accomPreferences) {
        this.context.setAccomPreferences(response.data.accomPreferences);
    }
    if (response.data.applicantContacts) {
        this.context.setApplicantContacts(response.data.applicantContacts);
    }
    if (response.data.emergencyContacts) {
        this.context.setEmergencyContacts(response.data.emergencyContacts);
    }
    this.state.router.navigate("/accapp/1");
  }

  displayLoginError(error) {
    console.log(error);
    if (error.response && error.response.data && error.response.data.message) {
      this.setState({loginError: `Unfortnuately we were unable to log you in because: ${error.response.data.message}`});
    } else {
      this.setState({loginError: UIConstants.LOGIN_ERROR});
    }
  }

  render() {
    let { email, password, windowWidth, loginError } = this.state;
    const isMobile = (windowWidth < 768);
    return (
      <div className="reglogpage-container login-application-page row">
        {(!isMobile) && (
            <LogoLeftColumn link="/" mediaDisplay={false}/>
        )}
        <div className={isMobile ? "reglog-centre" : "reglog-centre col-8"}>
          <h1 className="nw-heading reglog-heading">Login</h1>
          <div className="reglog-button-group-container">
            <div className="reglog-button-container">
              <Button 
                variant="outline-dark" 
                className="reglog-button"
                aria-label="Use Google"
                onClick={this.handleGoogleLogin}
              >
                <BsGoogle className="reglog-button-icon"/>Use Google
              </Button>
            </div>
            <div className="reglog-button-container">
              <Button 
                variant="outline-dark" 
                className="reglog-button"
                aria-label="Use Facebook"
                onClick={this.handleFacebookLogin}
              >
                <BsFacebook className="reglog-button-icon"/>Use Facebook
              </Button>
            </div>
            <div className="reglog-button-container">
              <Button 
                variant="outline-dark" 
                className="reglog-button"
                aria-label="Use Twitter"
                onClick={this.handleTwitterLogin}
              >
                <BsTwitter className="reglog-button-icon"/>Use Twitter
              </Button>
            </div>
            <div className="reglog-button-container">
              <Button 
                variant="outline-dark" 
                className="reglog-button"
                aria-label="Use Instagram"
                onClick={this.handleInstagramLogin}
              >
                <BsInstagram className="reglog-button-icon"/>Use Instagram
              </Button>
            </div>
            <div className="reglog-button-container">
              <Button 
                variant="outline-dark" 
                className="reglog-button"
                aria-label="Use LinkedIn"
                onClick={this.handleLinkedInLogin}
              >
                <BsLinkedin className="reglog-button-icon"/>Use LinkedIn
              </Button>
            </div>
          </div>
          <h1 className="nw-heading reglog-heading">Use Your Own Email</h1>
          <Form.Control 
            type="email"
            className="nw-input reglog-input"
            placeholder="Email"
            defaultValue={email}
            onChange={(e) => this.setState({email: e.currentTarget.value})}
          />
          <Form.Control 
            type="password"
            className="nw-input reglog-input"
            placeholder="Password"
            defaultValue={password}
            onChange={(e) => this.setState({password: e.currentTarget.value})}
          />
          <Button 
            variant="outline-dark" 
            className="nw-submit-button reglog-submit-button" 
            aria-label="Login to account"
            onClick={this.handleEmailLogin}
          >
            Login
          </Button>
          <p className="reglog-error" aria-live="polite">{loginError}</p>
        </div>
        {(!isMobile) && (
            <div className="reglog-right col-2"></div>
        )}
      </div>
    );
  }
}

export default withRouter(AccAppLoginPage);