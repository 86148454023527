import React from 'react';
import { Navigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { BsEnvelope } from 'react-icons/bs';
import { BsTelephone } from 'react-icons/bs';
import ReCAPTCHA from 'react-google-recaptcha';

import { withRouter } from '../../components/withRouter/withRouter.tsx';
import LogoLeftColumn from '../../components/logoLeftColumn/LogoLeftColumn.js';
import CustomPagination from '../../components/customPagination/CustomPagination';
import GlobalContext from '../../components/context/GlobalContext';
import AuthService from '../../services/AuthService.js';

import * as UIConstants from '../../constants/UIConstants';
import * as ValidationConstants from '../../constants/ValidationConstants';
import * as ServerConstants from '../../constants/ServerConstants';

import '../../styles/surveys.css';
import './SurveyThirdPage.css';

class SurveyThirdPage extends React.Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    this.captchaRef = React.createRef();
    this.state = {
      pagination : [
        { text: "1", link: "/survey/1", alert: "", cbflag: true, active: true }, 
        { text: "2", link: "/survey/2", alert: "", cbflag: true, active: true }, 
        { text: "3", link: "/survey/3", alert: "", cbflag: true, active: true }
      ],
      current : "3",
      validation: {
        evacueeHost: ValidationConstants.UNCHECKED,
        contactLater: ValidationConstants.UNCHECKED,
        contactMethod: ValidationConstants.UNCHECKED
      },
      contactLaterCss: "sv-contact-hide",
      contactTypeCss: "sv-contact-hide",
      saved: false,
      alertMessage: "",
      recaptcha: null,
      windowWidth: window.innerWidth,
      router: props.router
    }
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.onHostRepeatChange = this.onHostRepeatChange.bind(this);
    this.onContactLaterChange = this.onContactLaterChange.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.updateWindowWidth = this.updateWindowWidth.bind(this);
  }

  componentDidUpdate() {
    if (this.state.alertMessage !== "") {
      var alertmsg = this.state.alertMessage;
      this.setState({ alertMessage: ""}, () =>
        setTimeout((msg) => alert(msg), 250, alertmsg)
      );
    }
  }

  componentDidMount() {
    this.updateWindowWidth();
    window.addEventListener('resize', this.updateWindowWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }

  onHostRepeatChange(value) {
    this.context.setSurveyValues([{key: "hostRepeat", value: value}]);
    let v = this.state.validation;
    if (v.contactLater === ValidationConstants.INVALID || v.contactLater === ValidationConstants.INCOMPLETE) {
      v.contactLater = this.updateValidationType("contactLater", false);
    }
    if (v.contactMethod === ValidationConstants.INVALID || v.contactMethod === ValidationConstants.INCOMPLETE) {
      v.contactMethod = this.updateValidationType("contactMethod", false);
    }
    this.setState({
      contactLaterCss: value === "yes" ? "sv-contact-show" : "sv-contact-hide",
      validation: v
    });
  }

  onContactLaterChange(value) {
    this.context.setSurveyValues([{key: "contactLater", value: value}]);
    let v = this.state.validation;
    if (v.contactLater === ValidationConstants.INVALID || v.contactLater === ValidationConstants.INCOMPLETE) {
      v.contactLater = this.updateValidationType("contactLater", false);
    }
    if (v.contactMethod === ValidationConstants.INVALID || v.contactMethod === ValidationConstants.INCOMPLETE) {
      v.contactMethod = this.updateValidationType("contactMethod", false);
    }
    this.setState({
      validation: v
    });
  }

  handleSaveClick() {
    this.checkPageValidation((valid, msg) => {
      if (valid) {
        const token = this.captchaRef.current.getValue();
        AuthService.verifyCaptcha(token, (result) => {
          if (result) {
            this.saveSurvey();
          } else {
            this.setState({
              alertMessage: UIConstants.CAPTCHA_ERROR
            });
          }
        });
      } else {
        this.setState({
          alertMessage: msg
        });
      }
    });
  }

  /*
  verifyCaptcha(callback) {
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        token: this.captchaRef.current.getValue()
      })
    };
    fetch(ServerConstants.API_URL + '/captcha/verify', options)
    .then(async response => {
      const data = await response.json();
      // check for error response
      if (!response.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        return Promise.reject(error);
      }
      console.log(data);
      callback(data.success);
    })
    .catch(error => {
      console.error('Error occurred verifying captcha', error);
      callback(false);
    });
  }
  */

  saveSurvey() {
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        lga: this.context.survey.lga,
        evacuationReason: this.context.survey.evacuationReason,
        warningLevel: this.context.survey.warningLevel,
        shelterOnEvacuation: this.context.survey.shelterOnEvacuation,
        shelterOnEvacuationOther: this.context.survey.shelterOnEvacuationOther,
        shelterSatisfaction: this.context.survey.shelterSatisfaction,
        shelterSatisfactionReason: this.context.survey.shelterSatisfactionReason,
        shelterRepeat: this.context.survey.shelterRepeat,
        shelterRepeatReason: this.context.survey.shelterRepeatReason,
        evacueeHost: this.context.survey.evacueeHost,
        howEvacueeFound: this.context.survey.howEvacueeFound,
        hostRepeat: this.context.survey.hostRepeat,
        hostRepeatReason: this.context.survey.hostRepeatReason,
        contactLater: this.context.survey.contactLater,
        contactEmail: this.context.survey.contactEmail,
        contactPhone: this.context.survey.contactPhone
      })
    };
    fetch(ServerConstants.API_URL + '/surveys', options)
    .then(async response => {
      const data = await response.json();
      // check for error response
      if (!response.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        return Promise.reject(error);
      }
      this.context.setSurveyValues([{key: "id", value: data.id}]);
      this.state.router.navigate("/survey/complete");
    })
    .catch(error => {
      console.error('Error occurred saving survey', error);
      this.setState({ 
        alertMessage: error.toString() 
      });
    });
  }

  handlePageClick(link, alertText) {
    this.checkPageValidation((valid, msg) => {
      if (valid) {
        if (alertText !== "") {
          alert(alertText);
        } else {
            this.state.router.navigate(link);
        }
      } else {
        this.setState({
          alertMessage: msg
        });
      }
    });
  }

  checkPageValidation(callback) {
    this.updateValidation(() => {
      let v = this.state.validation;
      let result = ValidationConstants.UNCHECKED;
      if (v.evacueeHost === ValidationConstants.VALID && v.contactLater === ValidationConstants.VALID && v.contactMethod === ValidationConstants.VALID) {
        result = ValidationConstants.VALID;
      } else if (v.evacueeHost === ValidationConstants.INCOMPLETE || v.contactLater === ValidationConstants.INCOMPLETE || v.contactMethod === ValidationConstants.INCOMPLETE) {
        result = ValidationConstants.INCOMPLETE;
      }
      switch (result) {
        case ValidationConstants.VALID:
          callback(true, "");
          break;
        case ValidationConstants.UNCHECKED:
          callback(false, "");
          break;
        case ValidationConstants.INVALID:
          callback(false, UIConstants.SURVEY_PAGE_ERRORS);
          break;
        case ValidationConstants.INCOMPLETE:
          callback(false, UIConstants.SURVEY_PAGE_INCOMPLETE);
          break;
        default:
      }
    });
  }

  updateValidationType(type, set) {
    let s = this.context.survey;
    let value = ValidationConstants.UNCHECKED;
    switch (type) {
      case "evacueeHost":
        value = (s.evacueeHost === "") ? ValidationConstants.INCOMPLETE : ValidationConstants.VALID;
        break;
      case "contactLater":
        value = (s.hostRepeat !== "yes") ? ValidationConstants.VALID : (s.contactLater === "" ? ValidationConstants.INCOMPLETE : ValidationConstants.VALID);
        break;
      case "contactMethod":
        value = (s.contactLater !== "yes") ? ValidationConstants.VALID : (s.contactEmail === "" && s.contactPhone === "" ? ValidationConstants.INCOMPLETE : ValidationConstants.VALID);
        break;
      default:
    }
    if (set) {
      let validation = this.state.validation;
      validation[type] = value;
      this.setState({ validation });
    } else {
      return value;
    }
  }

  updateValidation(callback) {
    let validation = this.state.validation;
    validation.evacueeHost = this.updateValidationType("evacueeHost", false);
    validation.contactLater = this.updateValidationType("contactLater", false);
    validation.contactMethod = this.updateValidationType("contactMethod", false);
    this.setState({ validation }, callback());
  }

  updateWindowWidth() {
    this.setState({ windowWidth: window.innerWidth });
  }

  render() {
    if (this.context.survey.id !== 0) {
      return (<Navigate to={"/survey/complete"} replace={true}/>);
    } else {
      let { pagination, current, validation, contactLaterCss, windowWidth } = this.state;
      contactLaterCss = this.context.survey.hostRepeat === "yes" ? "sv-contact-show" : "sv-contact-hide";
        const isMobile = (windowWidth < 768);
      return (
        <div className="surveypage-container survey-third-page row">
          {(!isMobile) && (
            <LogoLeftColumn link="/" mediaDisplay={false}/>
          )}
          <div className={isMobile ? "survey-centre" : "survey-centre col-8"}>
            <CustomPagination pages={pagination} current={current} callback={(l,a) => this.handlePageClick(l,a)}/>
            <h1 className="nw-heading">Tell Us About Your Experience</h1>
            <div className="survey-yes-no-block-3">
              <div className={validation.evacueeHost === ValidationConstants.INVALID || validation.evacueeHost === ValidationConstants.INCOMPLETE ? "nw-title invalid" : "nw-title"}>If you did not leave your home or property during the natural disaster, did you hosts any evacuees? *</div>
              <ButtonGroup aria-label="Host evacuees - yes or no">
                <ToggleButton
                  className="nw-toggle-button survey-yes-no-button"
                  type="radio"
                  variant={validation.evacueeHost === ValidationConstants.INVALID || validation.evacueeHost === ValidationConstants.INCOMPLETE ? "outline-danger" : "outline-dark"}
                  checked={this.context.survey.evacueeHost === "yes"}
                  onClick={(e) => {
                    this.context.setSurveyValues([{key: "evacueeHost", value: "yes"}]);
                    if (validation.evacueeHost === ValidationConstants.INVALID || validation.evacueeHost === ValidationConstants.INCOMPLETE) {
                      this.updateValidationType("evacueeHost", true);
                    }
                  }}
                >
                  Yes
                </ToggleButton>
                <ToggleButton
                  className="nw-toggle-button survey-yes-no-button"
                  type="radio"
                  variant={validation.evacueeHost === ValidationConstants.INVALID || validation.evacueeHost === ValidationConstants.INCOMPLETE ? "outline-danger" : "outline-dark"}
                  checked={this.context.survey.evacueeHost === "no"}
                  onClick={(e) => {
                    this.context.setSurveyValues([{key: "evacueeHost", value: "no"}])
                    if (validation.evacueeHost === ValidationConstants.INVALID || validation.evacueeHost === ValidationConstants.INCOMPLETE) {
                      this.updateValidationType("evacueeHost", true);
                    }
                  }}
                >
                  No
                </ToggleButton>
              </ButtonGroup>
            </div>
            <h2 className="nw-title">If yes, how did you make your property available? How did you find an evacuee to host?</h2>
            <Form.Control 
                as="textarea"
                rows={3}
                value={this.context.survey.howEvacueeFound}
                className="nw-input survey-textarea survey-group-end-2"
                aria-label="Enter how property list & evacuee found"
                onChange={(e) => this.context.setSurveyValues([{key: "howEvacueeFound", value: e.currentTarget.value}])}
              />
            <div className="survey-yes-no-block">
              <div className="nw-inline-title">If you previously hosted a natural disaster evacuee, would you consider doing it again?</div>
              <ButtonGroup aria-label="Host evacuees again - yes or no">
                <ToggleButton
                  className="nw-toggle-button survey-yes-no-button"
                  type="radio"
                  variant="outline-dark"
                  checked={this.context.survey.hostRepeat === "yes"}
                  onClick={(e) => this.onHostRepeatChange("yes")}
                >
                  Yes
                </ToggleButton>
                <ToggleButton
                  className="nw-toggle-button survey-yes-no-button"
                  type="radio"
                  variant="outline-dark"
                  checked={this.context.survey.hostRepeat === "no"}
                  onClick={(e) => this.onHostRepeatChange("no")}
                >
                  No
                </ToggleButton>
              </ButtonGroup>
            </div>
            <h2 className="nw-title">If no, why?</h2>
            <Form.Control 
                as="textarea"
                rows={3}
                value={this.context.survey.hostRepeatReason}
                className="nw-input survey-textarea"
                aria-label="Enter reason for not hosting again"
                onChange={(e) => this.context.setSurveyValues([{key: "hostRepeatReason", value: e.currentTarget.value}])}
              />
              <div className={contactLaterCss}>
                <div className="survey-yes-no-block">
                  <div className={validation.contactLater === ValidationConstants.INVALID || validation.contactLater === ValidationConstants.INCOMPLETE ? "nw-title invalid" : "nw-title"}>Would you be happy for Nestwell to contact you about evacuee hosting in the future? *</div>
                  <ButtonGroup aria-label="Can Nestwell contact - yes or no">
                    <ToggleButton
                      className="nw-toggle-button survey-yes-no-button"
                      type="radio"
                      variant={validation.contactLater === ValidationConstants.INVALID || validation.contactLater === ValidationConstants.INCOMPLETE ? "outline-danger" : "outline-dark"}
                      checked={this.context.survey.contactLater === "yes"}
                      onClick={(e) => this.onContactLaterChange("yes")}
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      className="nw-toggle-button survey-yes-no-button"
                      type="radio"
                      variant={validation.contactLater === ValidationConstants.INVALID || validation.contactLater === ValidationConstants.INCOMPLETE ? "outline-danger" : "outline-dark"}
                      checked={this.context.survey.contactLater === "no"}
                      onClick={(e) => this.onContactLaterChange("no")}
                    >
                      No
                    </ToggleButton>
                  </ButtonGroup>
                </div>
              </div>
              <div className="sv-contact-show">
                <div className={validation.contactMethod === ValidationConstants.INVALID || validation.contactMethod === ValidationConstants.INCOMPLETE ? "nw-title sv3-title invalid" : "nw-title sv3-title"}>
                If you would like to find out more about Nestwell and how you can help your community during a natural disaster, please provide your preferred contact details
                </div>
                <div className="sv-contact-block">
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="contactEmailTitle" className="sv-contact-block-title" aria-label="Email">
                      <BsEnvelope/>
                    </InputGroup.Text>
                    <Form.Control
                      placeholder="Email"
                      className={validation.contactMethod === ValidationConstants.INVALID || validation.contactMethod === ValidationConstants.INCOMPLETE ? "sv-contact-input invalid" : "sv-contact-input"}
                      value={this.context.survey.contactEmail}
                      aria-label="Email"
                      aria-describedby="contactEmailTitle"
                      onChange={(e) => this.context.setSurveyValues([{key: "contactEmail", value: e.currentTarget.value}])}
                    />
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="contactPhoneTitle" className="sv-contact-block-title" aria-label="Phone">
                      <BsTelephone/>
                    </InputGroup.Text>
                    <Form.Control
                      placeholder="Phone"
                      className={validation.contactMethod === ValidationConstants.INVALID || validation.contactMethod === ValidationConstants.INCOMPLETE ? "sv-contact-input invalid" : "sv-contact-input"}
                      value={this.context.survey.contactPhone}
                      aria-label="Phone"
                      aria-describedby="contactPhoneTitle"
                      onChange={(e) => this.context.setSurveyValues([{key: "contactPhone", value: e.currentTarget.value}])}
                    />
                  </InputGroup>
                </div>
              </div>
              <div className="survey-group-end"></div>
              <ReCAPTCHA sitekey="6Lcg9sokAAAAACu5MWE_ns4yzdai3h0qFokQawL7" ref={this.captchaRef}/>
              <Button variant="outline-dark" className="nw-submit-button nw-bottom-button" onClick={this.handleSaveClick}>Save & Finish</Button>
              <div className="nw-error">{this.state.errorMessage}</div>
          </div>
          {(!isMobile) && (
            <div className="survey-right col-2"></div>
          )}
        </div>
      );
    }
  }
}

export default withRouter(SurveyThirdPage);