import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { BsGoogle, BsFacebook, BsTwitter, BsInstagram, BsLinkedin } from 'react-icons/bs';
import ReCAPTCHA from 'react-google-recaptcha';
import validator from 'validator';

import { withRouter } from '../../components/withRouter/withRouter.tsx';
import LogoLeftColumn from '../../components/logoLeftColumn/LogoLeftColumn.js';
import GlobalContext from '../../components/context/GlobalContext';
import AuthService from '../../services/AuthService.js';
import * as UIConstants from '../../constants/UIConstants';
import * as AuthConstants from '../../constants/AuthConstants';

import '../../styles/registerlogin.css';
import './OfferRegistrationPage.css';

class OfferRegistrationPage extends React.Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    this.captchaRef = React.createRef();
    this.state = {
      email: "",
      password: "",
      confirmPassword: "",
      windowWidth: window.innerWidth,
      router: props.router,
      registerError: ""
    };
    this.handleGoogleRegister = this.handleGoogleRegister.bind(this);
    this.handleFacebookRegister = this.handleFacebookRegister.bind(this);
    this.handleTwitterRegister = this.handleTwitterRegister.bind(this);
    this.handleInstagramRegister = this.handleInstagramRegister.bind(this);
    this.handleLinkedInRegister = this.handleLinkedInRegister.bind(this);
    this.handleEmailRegister = this.handleEmailRegister.bind(this);
    this.completeRegistration = this.completeRegistration.bind(this);
    this.displayRegistrationError = this.displayRegistrationError.bind(this);
    this.updateWindowWidth = this.updateWindowWidth.bind(this);
  }

  componentDidMount() {
    this.updateWindowWidth();
    window.addEventListener('resize', this.updateWindowWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }

  updateWindowWidth() {
    this.setState({ windowWidth: window.innerWidth });
  }

  handleGoogleRegister() {
    AuthService.googleSignUp()
    .then(signUpResponse => {
      this.completeRegistration(signUpResponse);
    })
    .catch(error => {
      this.displayRegistrationError(error);
    });
  }

  handleFacebookRegister() {
    AuthService.facebookSignUp()
    .then(signUpResponse => {
      this.completeRegistration(signUpResponse);
    })
    .catch(error => {
      this.displayRegistrationError(error);
    });
  }

  handleTwitterRegister() {
    AuthService.twitterSignUp()
    .then(signUpResponse => {
      this.completeRegistration(signUpResponse);
    })
    .catch(error => {
      this.displayRegistrationError(error);
    });
  }

  handleInstagramRegister() {
    AuthService.instagramSignUp()
    .then(signUpResponse => {
      this.completeRegistration(signUpResponse);
    })
    .catch(error => {
      this.displayRegistrationError(error);
    });
  }

  handleLinkedInRegister() {
    AuthService.linkedinSignUp()
    .then(signUpResponse => {
      this.completeRegistration(signUpResponse);
    })
    .catch(error => {
      this.displayRegistrationError(error);
    });
  }

  handleEmailRegister() {
    if (!validator.isEmail(this.state.email)) {
      this.setState({registerError: UIConstants.EMAIL_INVALID_ERROR});
    } else if (this.state.password !== this.state.confirmPassword) {
      this.setState({registerError: UIConstants.DIFFERENT_PASSWORDS_ERROR});
    } else {
      const token = this.captchaRef.current.getValue();
      AuthService.verifyCaptcha(token, (captchaResponse) => {
        if (captchaResponse) {
          AuthService.localSignUp({
            username: this.state.email,
            email: this.state.email,
            password: this.state.password,
            roles: [AuthConstants.Roles.USER.name, AuthConstants.Roles.OWNER.name]
          })
          .then(signUpResponse => {
            this.completeRegistration(signUpResponse);
          })
          .catch(error => {
            this.displayRegistrationError(error);
          });
        } else {
          this.setState({registerError: UIConstants.CAPTCHA_ERROR});
        }
      });
    }
  }

  completeRegistration(response) {
    console.log(response);
    const userId = parseInt(response.userid);
    const name = response.name;
    this.context.setUserValues([
      { key: "userId", value: userId },
      { key: "username", value: name }
    ]);
    this.context.setAccomApplicantValues([
      { key: "userId", value: userId },
      { key: "name", value: name },
    ]);
    this.state.router.navigate("/offering/1");
  }

  displayRegistrationError(error) {
    console.log(error);
    if (error.response && error.response.data && error.response.data.message) {
      this.setState({registerError: `Unfortnuately we were unable to to complete your registration because: ${error.response.data.message}`});
    } else {
      this.setState({registerError: UIConstants.REGISTER_ERROR});
    }
  }

  render() {
    let { email, password, confirmPassword, registerError, windowWidth } = this.state;
    const isMobile = (windowWidth < 768);
    return (
      <div className="reglogpage-container login-application-page row">
        {(!isMobile) && (
          <LogoLeftColumn link="/" mediaDisplay={false}/>
        )}
        <div className={isMobile ? "reglog-centre" : "reglog-centre col-8"}>
          <h1 className="nw-heading reglog-heading">Create An Account</h1>
          <div className="reglog-button-group-container">
            <div className="reglog-button-container">
                <a href="https://api.nestwell.com.au/auth/google/signup">
                    <Button 
                        variant="outline-dark" 
                        className="reglog-button"
                        aria-label="Use Google"
                    >
                        <BsGoogle className="reglog-button-icon"/>Use Google
                    </Button>
                </a>
            </div>
            <div className="reglog-button-container">
                <a href="https://api.nestwell.com.au/auth/facebook/signup">
                    <Button 
                        variant="outline-dark" 
                        className="reglog-button"
                        aria-label="Use Facebook"
                    >
                        <BsFacebook className="reglog-button-icon"/>Use Facebook
                    </Button>
                </a>
            </div>
            <div className="reglog-button-container">
                <a href="https://api.nestwell.com.au/auth/twitter/signup">
                    <Button 
                        variant="outline-dark" 
                        className="reglog-button"
                        aria-label="Use Twitter"
                    >
                        <BsTwitter className="reglog-button-icon"/>Use Twitter
                    </Button>
                </a>
            </div>
            <div className="reglog-button-container">
                <a href="https://api.nestwell.com.au/auth/instagram/signup">
                    <Button 
                        variant="outline-dark" 
                        className="reglog-button"
                        aria-label="Use Instagram"
                    >
                        <BsInstagram className="reglog-button-icon"/>Use Instagram
                    </Button>
                </a>
            </div>
            <div className="reglog-button-container">
                <a href="https://api.nestwell.com.au/auth/linkedin/signup">
                    <Button 
                        variant="outline-dark" 
                        className="reglog-button"
                        aria-label="Use LinkedIn"
                    >
                        <BsLinkedin className="reglog-button-icon"/>Use LinkedIn
                    </Button>
                </a>
            </div>
          </div>
          <h1 className="nw-heading reglog-heading">Use Your Own Email</h1>
          <label htmlFor="email" className="reglog-reg-label">Email</label>
          <Form.Control
            id="email" 
            type="text"
            className="nw-input reglog-reg-input"
            value={email}
            onChange={(e) => this.setState({email: e.currentTarget.value})}
          />
          <label htmlFor="password" className="reglog-reg-label">Password</label>
          <Form.Control
            id="password" 
            type="password"
            className="nw-input reglog-reg-input"
            value={password}
            onChange={(e) => this.setState({password: e.currentTarget.value})}
          />
          <label htmlFor="confirmPassword" className="reglog-reg-label">Confirm Password</label>
          <Form.Control
            id="confirmPassword" 
            type="password"
            className="nw-input reglog-reg-input"
            value={confirmPassword}
            onChange={(e) => this.setState({confirmPassword: e.currentTarget.value})}
          />
          <ReCAPTCHA sitekey="6Lcg9sokAAAAACu5MWE_ns4yzdai3h0qFokQawL7" ref={this.captchaRef} className="reg-captcha"/>
          <Button 
            variant="outline-dark" 
            className="nw-submit-button reglog-submit-button" 
            aria-label="Register account"
            onClick={this.handleEmailRegister}
          >
            Register
          </Button>
          <p className="reglog-error" aria-live="polite">{registerError}</p>
        </div>
        {(!isMobile) && (
            <div className="reglog-right col-2"></div>
        )}
      </div>
    );
  }
}

export default withRouter(OfferRegistrationPage);