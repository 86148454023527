/* route : /offering/2 */

import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { ToggleButton } from 'react-bootstrap';
import { ButtonGroup } from 'react-bootstrap';

import { withRouter } from '../../components/withRouter/withRouter.tsx';
import LogoLeftColumn from '../../components/logoLeftColumn/LogoLeftColumn.js';
import CustomPagination from '../../components/customPagination/CustomPagination';
import GlobalContext from '../../components/context/GlobalContext';
import DataService from '../../services/DataService.js';
import * as AccommodationConstants from '../../constants/AccommodationConstants';

import '../../styles/offerings.css';
import './OfferTypeListingPage.css';

class OfferTypeListingPage extends React.Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    this.state = {
      pagination: [
        { text: "1", link: "/offering/1", alert: "", cbflag: true, active: true }, 
        { text: "2", link: "/offering/2", alert: "", cbflag: true, active: true }, 
        { text: "3", link: "/offering/3", alert: "", cbflag: true, active: true },
        { text: "4", link: "/offering/4", alert: "", cbflag: true, active: true },
        { text: "5", link: "/offering/5", alert: "", cbflag: true, active: true },
        { text: "6", link: "/offering/6", alert: "", cbflag: true, active: true }
      ],
      current: "2",
      windowWidth: window.innerWidth,
      router: props.router
    };
    this.updateWindowWidth = this.updateWindowWidth.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.checkPageValidation = this.checkPageValidation.bind(this);
  }

  componentDidMount() {
    this.updateWindowWidth();
    window.addEventListener('resize', this.updateWindowWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }

  updateWindowWidth() {
    this.setState({ windowWidth: window.innerWidth });
  }

  handleSaveClick() {
    DataService.saveOfferData(this.context)
    .then(response => {
      if (response.data) {
        if (response.data.owner) {
          this.context.setOwnerValues([{ key: "id", value: response.data.owner.id}]);
        }
        if (response.data.accommodation) {
          this.context.setAccommodationValues([{ key: "id", value: response.data.owner.id}]);
        }
        if (response.data.accomAmenities) {
          this.context.setAccomAmenities(response.data.accomAmenities);
        }
        if (response.data.rooms) {
          this.context.setRooms(response.data.rooms);
        }
      }
      this.state.router.navigate("/");
    })
    .catch(error => {
      console.error('Error occurred saving survey', error);
      alert("Unable to save data : " + error);
    });
  }

  handleNextClick() {
    this.state.router.navigate("/offering/3");
  }

  handlePageClick(link, alertText) {
    this.checkPageValidation((valid, msg) => {
      if (valid) {
        if (alertText !== "") {
          alert(alertText);
        } else {
          this.state.router.navigate(link);
        }
      } else {
        this.setState({
          alertMessage: msg
        });
      }
    });
  }

  checkPageValidation(callback) {
    /* TODO: Add functionality to handle validation */
    callback(true, "");
  }

  render() {
    let { pagination, current, windowWidth } = this.state;
    const isMobile = (windowWidth < 768);
    return (
      <div className="offer-page-container offer-basic-details-page row">
        {(!isMobile) && (
          <LogoLeftColumn link="/" mediaDisplay={true}/>
        )}
        <div className={isMobile ? "offer-centre" : "offer-centre col-8"}>
          <CustomPagination pages={pagination} current={current} callback={(l,a) => this.handlePageClick(l,a)}/>
          <h1 className="nw-heading offer-heading">Tell Us More About The Accommodation</h1>
          <div className="offer-section">
            <h2 className="nw-title">In the event of a natural disaster, what conditions would you place on offering this accommodation to the temporarily displaced ?</h2>
            <ButtonGroup>
              {AccommodationConstants.PAYMENT_CONDITIONS.map((cond, index) => (
                <ToggleButton
                  key={`cond-${index}`}
                  className="nw-toggle-button"
                  type="radio"
                  variant="outline-dark"
                  value={cond.value}
                  checked={this.context.accommodation.paymentConditions === cond.value}
                  onClick={(e) => {
                    this.context.setAccommodationValues([{ key: "paymentConditions", value: cond.value }]);
                  }}
                >
                  {cond.label}
                </ToggleButton>
              ))}
            </ButtonGroup>
          </div>
          <div className="offer-section">
            <h2 className="nw-title">What type of accommodation are you offering?</h2>
            <Form.Select
              className="nw-select offer-accom-type-select" 
              value={this.context.accommodation.accommodationType}
              onChange={(e) => {
                this.context.setAccommodationValues([{ key: "accommodationType", value: e.target.value }]);
              }}
              aria-label="Accommodation type" 
            >
              <option key="acc-opt-x"></option>
              {AccommodationConstants.ACCOMMODATION_TYPES.map((type, index) => (
                <option key={`acc-opt-${index}`} value={type.value}>{type.label}</option>
              ))}
            </Form.Select>
            <div className="offer-row">
              <h2 className="nw-inline-title offer-accom-type-other-label">If other: </h2>
              <Form.Control 
                type="text"
                className="nw-input"
                value={this.context.accommodation.accommodationTypeOther}
                onChange={(e) => {
                  this.context.setAccommodationValues([{ key: "accommodationTypeOther", value: e.target.value }]);
                }}
              />
            </div>
          </div>
          <div className="offer-section">
            <h2 className="nw-title">Have you ever listed your accommodation on any holiday rental websites (Airbnb, Stayz, etc)?</h2>
            <ButtonGroup className="offer-button-group-full">
              {AccommodationConstants.PRIOR_LISTING.map((prior, index) => (
                <ToggleButton
                  key={`prev-list-${index}`}
                  className="nw-toggle-button"
                  type="radio"
                  variant="outline-dark"
                  value={prior.value}
                  checked={this.context.accommodation.priorListing === prior.value}
                  onClick={(e) => {
                    this.context.setAccommodationValues([{ key: "priorListing", value: prior.value }]);
                  }}
                >
                  {prior.label}
                </ToggleButton>
              ))}
            </ButtonGroup>
          </div>
          <div className="offer-section">
            <div className="offer-yes-no-block">
              <h2 className="nw-title">Do you currently have an active listing for this accommodation?</h2>
              <ButtonGroup aria-label="Active listing - yes or no">
                <ToggleButton
                  className="nw-toggle-button offer-yes-no-button"
                  type="radio"
                  variant="outline-dark"
                  checked={this.context.accommodation.activeListing === "yes"}
                  onClick={(e) => {
                    this.context.setAccommodationValues([{ key: "activeListing", value: "yes" }]);
                  }}
                >
                  Yes
                </ToggleButton>
                <ToggleButton
                  className="nw-toggle-button offer-yes-no-button"
                  type="radio"
                  variant="outline-dark"
                  checked={this.context.accommodation.activeListing === "no"}
                  onClick={(e) => {
                    this.context.setAccommodationValues([{ key: "activeListing", value: "no" }]);
                  }}
                >
                  No
                </ToggleButton>
              </ButtonGroup>
            </div>
            <h2 className="nw-title">Listing URL:</h2>
            <Form.Control 
              type="text"
              className="nw-input"
              value={this.context.accommodation.listingURL}
              onChange={(e) => {
                this.context.setAccommodationValues([{ key: "listingURL", value: e.target.value }]);
              }}
            />
          </div>
          <div className="offer-section">
            <div className="offer-yes-no-block">
              <h2 className="nw-title">Is your accommodation listed on the NSW STRA register?</h2>
              <ButtonGroup aria-label="NSW STRA register listing - yes or no">
                <ToggleButton
                  className="nw-toggle-button offer-yes-no-button"
                  type="radio"
                  variant="outline-dark"
                  checked={this.context.accommodation.straListing === "yes"}
                  onClick={(e) => {
                    this.context.setAccommodationValues([{ key: "straListing", value: "yes" }]);
                  }}
                >
                  Yes
                </ToggleButton>
                <ToggleButton
                  className="nw-toggle-button offer-yes-no-button"
                  type="radio"
                  variant="outline-dark"
                  checked={this.context.accommodation.straListing === "no"}
                  onClick={(e) => {
                    this.context.setAccommodationValues([{ key: "straListing", value: "no" }]);
                  }}
                >
                  No
                </ToggleButton>
              </ButtonGroup>
            </div>
            <div className="offer-row offer-property-row">
              <h2 className="nw-inline-title offer-property-id-label">If yes, what is the property ID?</h2>
              <div className="offer-property-id-text-wrapper">
                <Form.Control 
                  type="text"
                  className="nw-input offer-property-id-text"
                  value={this.context.accommodation.straPropertyId}
                  onChange={(e) => {
                    this.context.setAccommodationValues([{ key: "straPropertyId", value: "no" }]);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="offer-button-row">
            <Button 
                variant="outline-dark" 
                className="nw-submit-button offer-bottom-button" 
                aria-label="Save and leave"
                onClick={() => {}}
              >
                Save &amp; Leave
              </Button>
              <Button 
                variant="outline-dark" 
                className="nw-submit-button offer-bottom-button" 
                aria-label="Go to next page"
                onClick={this.handleNextClick}
              >
                Next &gt;&gt;
              </Button>
          </div>
        </div>
        {(!isMobile) && (
            <div className="offer-right col-2"></div>
        )}
      </div>
    );
  }
}

export default withRouter(OfferTypeListingPage);