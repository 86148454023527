import React from 'react';
import PropTypes from 'prop-types';

import { withRouter } from '../../components/withRouter/withRouter.tsx';
import CustomPaginationButton from './CustomPaginationButton';

import './CustomPagination.css';

class CustomPagination extends React.Component {
    static propTypes = {
        pages: PropTypes.array,
        current: PropTypes.string,
        callback: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.state = {
            pages: props.pages,
            current: props.current,
            callback: props.callback,
            router: props.router
        }
    }

    pageClicked(cbflag, link, alertText) {
        if (cbflag) {
            this.state.callback(link, alertText);
        } else if (alertText !== "") {
            alert(alertText);
        } else {
            this.state.router.navigate(link);
        }
    }

    render() {
        let { pages, current } = this.state;
        let html = pages.map(function(page, index) {
            if (page.text === current) {
                if (index < pages.length - 1) {
                    return (
                        <div key={index} className="nw-cust-pg-item">
                            <div className="nw-cust-pg-selected">{page.text}</div>
                            <div className="nw-cust-pg-stalk"></div>
                        </div>
                    );
                } else {
                    return (
                        <div key={index} className="nw-cust-pg-selected">{page.text}</div>
                    );
                }
            } else {
                if (index < pages.length - 1) {
                    if (page.active) {
                        return (
                            <div key={index} className="nw-cust-pg-item">
                                <CustomPaginationButton text={page.text} link={page.link} alert={page.alert} cbflag={page.cbflag} handleClick={(cbflag, link, alert) => this.pageClicked(cbflag, link, alert)}/>
                                <div className="nw-cust-pg-stalk"></div>
                            </div>
                        );
                    } else {
                        return (
                            <div key={index} className="nw-cust-pg-item">
                                <div className="nw-cust-pg">{page.text}</div>
                                <div className="nw-cust-pg-stalk"></div>
                            </div>
                        );
                    }
                } else {
                    if (page.active) {
                        return (
                            <CustomPaginationButton key={index} text={page.text} link={page.link} handleClick={(cbflag, link, alert) => this.pageClicked(cbflag, link, alert)}/>
                        );
                    } else {
                        return (
                            <div className="nw-cust-pg">{page.text}</div>
                        );
                    }
                }
            }
        }, this);
        return (
            <div className="nw-cust-pg-container">
                { html }
            </div>
        );
    }
}

export default withRouter(CustomPagination);