import React from 'react';
import Button from 'react-bootstrap/Button';

import { withRouter } from '../../components/withRouter/withRouter.tsx';
import Logo from '../../assets/images/bird-house-logo.svg';
import * as UIConstants from '../../constants/UIConstants';

import '../../styles/surveys.css';
import './SurveyFrontPage.css';

class SurveyFrontPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            router: props.router
        }
        this.startSurvey = this.startSurvey.bind(this);
    }

    startSurvey() {
        this.state.router.navigate("/survey/1");
    }
    
    render() {
        return (
            <div className="survey-frontpage-container">
                <div className="survey-front-logo-container">
                    <img src={Logo} alt="Logo" className="survey-front-logo"/>
                    <h1 className="nw-heading">Nestwell Emergency Housing Survey</h1>
                </div>
                <div className="survey-front-text-container">
                    <div className="survey-front-text">
                    Nestwell helps people find or provide help to those fleeing natural disasters in Rural and Regional Communities. 
                    It is a platform to assist with emergency and temporary accommodation during and in the Recovery phase of a disaster.
                    <br/><br/>
                    This survey is to collect information on what you or your family experienced during a natural disaster in your community with regards 
                    to accommodation. Whether you had to seek shelter or offered shelter for those in your community, we would appreciate you sharing your 
                    experience. Your collaboration will help us provide the best possible service to ensure housing and accommodation resilience during 
                    natural disasters in your area. 
                    </div>
                </div>
                <div className="survey-front-bottom">
                    <Button 
                        variant="outline-dark" 
                        className="nw-basic-button survey-large-front-button" 
                        aria-label="Start survey"
                        onClick={this.startSurvey}
                    >
                        {UIConstants.SURVEY_BUTTON_LABEL}
                    </Button>
                </div>
            </div>
        );
    }
}

export default withRouter(SurveyFrontPage);