/* route : /offering/5 */

import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { ToggleButton } from 'react-bootstrap';
import { ButtonGroup } from 'react-bootstrap';

import { withRouter } from '../../components/withRouter/withRouter.tsx';
import LogoLeftColumn from '../../components/logoLeftColumn/LogoLeftColumn.js';
import CustomPagination from '../../components/customPagination/CustomPagination';
import GlobalContext from '../../components/context/GlobalContext';
import DataService from '../../services/DataService.js';
import * as AccommodationConstants from '../../constants/AccommodationConstants';

import '../../styles/offerings.css';
import './OfferAmenityDetailsPage.css';

class OfferAmenityDetailsPage extends React.Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    this.state = {
      pagination: [
        { text: "1", link: "/offering/1", alert: "", cbflag: true, active: true }, 
        { text: "2", link: "/offering/2", alert: "", cbflag: true, active: true }, 
        { text: "3", link: "/offering/3", alert: "", cbflag: true, active: true },
        { text: "4", link: "/offering/4", alert: "", cbflag: true, active: true },
        { text: "5", link: "/offering/5", alert: "", cbflag: true, active: true },
        { text: "6", link: "/offering/6", alert: "", cbflag: true, active: true }
      ],
      current: "5",
      windowWidth: window.innerWidth,
      router: props.router
    };
    this.updateWindowWidth = this.updateWindowWidth.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);    
    this.handleNextClick = this.handleNextClick.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.checkPageValidation = this.checkPageValidation.bind(this);
  }

  componentDidMount() {
    this.updateWindowWidth();
    window.addEventListener('resize', this.updateWindowWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }

  updateWindowWidth() {
    this.setState({ windowWidth: window.innerWidth });
  }

  handleSaveClick() {
    DataService.saveOfferData(this.context)
    .then(response => {
      if (response.data) {
        if (response.data.owner) {
          this.context.setOwnerValues([{ key: "id", value: response.data.owner.id}]);
        }
        if (response.data.accommodation) {
          this.context.setAccommodationValues([{ key: "id", value: response.data.owner.id}]);
        }
        if (response.data.accomAmenities) {
          this.context.setAccomAmenities(response.data.accomAmenities);
        }
        if (response.data.rooms) {
          this.context.setRooms(response.data.rooms);
        }
      }
      this.state.router.navigate("/");
    })
    .catch(error => {
      console.error('Error occurred saving survey', error);
      alert("Unable to save data : " + error);
    });
  }

  handleNextClick() {
    this.state.router.navigate("/offering/6");
  }

  handlePageClick(link, alertText) {
    this.checkPageValidation((valid, msg) => {
      if (valid) {
        if (alertText !== "") {
          alert(alertText);
        } else {
          this.state.router.navigate(link);
        }
      } else {
        this.setState({
          alertMessage: msg
        });
      }
    });
  }

  checkPageValidation(callback) {
    /* TODO: Add functionality to handle validation */
    callback(true, "");
  }

  render() {
    let { pagination, current, windowWidth } = this.state;
    const isMobile = (windowWidth < 768);
    return (
      <div className="offer-page-container offer-amenity-details-page row">
        {(!isMobile) && (
          <LogoLeftColumn link="/" mediaDisplay={false}/>
        )}
        <div className={isMobile ? "offer-centre" : "offer-centre col-8"}>
          <CustomPagination pages={pagination} current={current} callback={(l,a) => this.handlePageClick(l,a)}/>
          <h1 className="nw-heading offer-heading">Tell Us More About The Accommodation</h1>
          <div className="offer-section">
            <h2 className="nw-title">Do you have allocated parking for guests close to your accommodation?</h2>
            <ButtonGroup aria-label="Allocated parking" className="offer-parking-group">
              {AccommodationConstants.PARKING_TYPES.map((type, index) => (
                <ToggleButton
                  key={`parking-${index}`}
                  className="nw-toggle-button"
                  type="radio"
                  variant="outline-dark"
                  value={type.value}
                  checked={this.context.accommodation.allocatedParking === type.value}
                  onClick={(e) => {
                    this.context.setAccommodationValues([{ key: "allocatedParking", value: type.value }]);
                  }}
                >
                  {type.label}
                </ToggleButton>
              ))}
            </ButtonGroup>
          </div>
          <div className="offer-section">
            <h2 className="nw-title">Does your accommodation have available grabbing or assistive rails in any of the following areas?</h2>
            <div className="row offer-amenity-row">
              {AccommodationConstants.RAILING_LOCATIONS.map((rail, index) => (
                <div key={`rail-div-${index}`} className={rail.class}>
                  <ToggleButton
                    key={`rail-${index}`}
                    className={"nw-toggle-button " + rail.buttonClass}
                    type="radio"
                    variant="outline-dark"
                    checked={this.context.accommodation[rail.field] === "yes"}
                    onClick={(e) => {
                      if (this.context.accommodation[rail.field] === "yes") {
                        this.context.setAccommodationValues([{ key: rail.field, value: "no" }]);
                      } else {
                        this.context.setAccommodationValues([{ key: rail.field, value: "yes" }]);
                      }
                    }}
                  >
                    {rail.label}
                  </ToggleButton>
                </div>
              ))}
            </div>
          </div>
          <div className="offer-section">
            <h2 className="nw-title">Do you have any of the following bedroom amenities?</h2>
            <div className="row offer-amenity-row">
              {AccommodationConstants.BEDROOM_AMENITIES.map((amenity, index) => (
                <div key={`bed-div-${index}`} className={amenity.class}>
                  <ToggleButton
                    key={`bed-${index}`}
                    className={"nw-toggle-button " + amenity.buttonClass}
                    type="radio"
                    variant="outline-dark"
                    checked={this.context.accomAmenities.indexOf(amenity.value) > -1}
                    onClick={(e) => {
                      let accomAmenities = JSON.parse(JSON.stringify(this.context.accomAmenities));
                      const index = accomAmenities.indexOf(amenity.value);
                      if (index > -1) {
                        accomAmenities.splice(index, 1);
                      } else {
                        accomAmenities.push(amenity.value);
                      }
                      this.context.setAccomAmenities(accomAmenities);
                    }}
                  >
                    {amenity.label}
                  </ToggleButton>
                </div>
              ))}
            </div>
          </div>
          <div className="offer-section">
            <h2 className="nw-title">Do you have any of the following bathroom amenities?</h2>
            <div className="row offer-amenity-row">
              {AccommodationConstants.BATHROOM_AMENITIES.map((amenity, index) => (
                <div key={`bath-div-${index}`} className={amenity.class}>
                  <ToggleButton
                    key={`bath-${index}`}
                    className={"nw-toggle-button " + amenity.buttonClass}
                    type="radio"
                    variant="outline-dark"
                    checked={this.context.accomAmenities.indexOf(amenity.value) > -1}
                    onClick={(e) => {
                      let accomAmenities = JSON.parse(JSON.stringify(this.context.accomAmenities));
                      const index = accomAmenities.indexOf(amenity.value);
                      if (index > -1) {
                        accomAmenities.splice(index, 1);
                      } else {
                        accomAmenities.push(amenity.value);
                      }
                      this.context.setAccomAmenities(accomAmenities);
                    }}
                  >
                    {amenity.label}
                  </ToggleButton>
                </div>
              ))}
            </div>
          </div>
          <div className="offer-section">
            <h2 className="nw-title">Do you have any of the following kitchen amenities?</h2>
            <div className="row offer-amenity-row">
              {AccommodationConstants.KITCHEN_AMENITIES.map((amenity, index) => (
                <div key={`ktchn-div-${index}`} className={amenity.class}>
                  <ToggleButton
                    key={`ktchn-${index}`}
                    className={"nw-toggle-button " + amenity.buttonClass}
                    type="radio"
                    variant="outline-dark"
                    checked={this.context.accomAmenities.indexOf(amenity.value) > -1}
                    onClick={(e) => {
                      let accomAmenities = JSON.parse(JSON.stringify(this.context.accomAmenities));
                      const index = accomAmenities.indexOf(amenity.value);
                      if (index > -1) {
                        accomAmenities.splice(index, 1);
                      } else {
                        accomAmenities.push(amenity.value);
                      }
                      this.context.setAccomAmenities(accomAmenities);
                    }}
                  >
                    {amenity.label}
                  </ToggleButton>
                </div>
              ))}
            </div>
          </div>
          <div className="offer-section">
            <h2 className="nw-title">Do you have any of the following laundry amenities?</h2>
            <div className="row offer-amenity-row">
              {AccommodationConstants.LAUNDRY_AMENITIES.map((amenity, index) => (
                <div key={`lndry-div-${index}`} className={amenity.class}>
                  <ToggleButton
                    key={`lndry-${index}`}
                    className={"nw-toggle-button " + amenity.buttonClass}
                    type="radio"
                    variant="outline-dark"
                    checked={this.context.accomAmenities.indexOf(amenity.value) > -1}
                    onClick={(e) => {
                      let accomAmenities = JSON.parse(JSON.stringify(this.context.accomAmenities));
                      const index = accomAmenities.indexOf(amenity.value);
                      if (index > -1) {
                        accomAmenities.splice(index, 1);
                      } else {
                        accomAmenities.push(amenity.value);
                      }
                      this.context.setAccomAmenities(accomAmenities);
                    }}
                  >
                    {amenity.label}
                  </ToggleButton>
                </div>
              ))}
            </div>
          </div>
          <div className="offer-button-row">
            <Button 
                variant="outline-dark" 
                className="nw-submit-button offer-bottom-button" 
                aria-label="Save and leave"
                onClick={() => {}}
              >
                Save &amp; Leave
              </Button>
              <Button 
                variant="outline-dark" 
                className="nw-submit-button offer-bottom-button" 
                aria-label="Go to next page"
                onClick={this.handleNextClick}
              >
                Next &gt;&gt;
              </Button>
          </div>
        </div>
        {(!isMobile) && (
            <div className="offer-right col-2"></div>
        )}
      </div>
    );
  }
}

export default withRouter(OfferAmenityDetailsPage);