import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import GlobalContext from './components/context/GlobalContext';
import FrontPage from './pages/frontPage/FrontPage';

import OfferRegistrationPage from './pages/offerRegistrationPage/OfferRegistrationPage';
import OfferLoginPage from './pages/offerLoginPage/OfferLoginPage';
import OfferBasicDetailsPage from './pages/offerBasicDetailsPage/OfferBasicDetailsPage';
import OfferTypeListingPage from './pages/offerTypeListingPage/OfferTypeListingPage';
import OfferPeriodCapacityPage from './pages/offerPeriodCapacityPage/OfferPeriodCapacityPage';
import OfferAccessibilityPage from './pages/offerAccessibilityPage/OfferAccessibilityPage';
import OfferAmenityDetailsPage from './pages/offerAmenityDetailsPage/OfferAmenityDetailsPage';
import OfferRoomsPage from './pages/offerRoomsPage/OfferRoomsPage';
import OfferCompletePage from './pages/offerCompletePage/OfferCompletePage';

import AccAppRegistrationPage from './pages/accAppRegistrationPage/AccAppRegistrationPage';
import AccAppLoginPage from './pages/accAppLoginPage/AccAppLoginPage';
import AccAppBasicDetailsPage from './pages/accAppBasicDetailsPage/AccAppBasicDetailsPage';
import AccAppContactsPage from './pages/accAppContactsPage/AccAppContactsPage';
import AccAppNumbersNeedsPage from './pages/accAppNumbersNeedsPage/AccAppNumbersNeedsPage';
import AccAppTypeFeePage from './pages/accAppTypeFeePage/AccAppTypeFeePage';
import AccAppCompletePage from './pages/accAppCompletePage/AccAppCompletePage';

import SurveyFrontPage from './pages/surveyFrontPage/SurveyFrontPage';
import SurveyFirstPage from './pages/surveyFirstPage/SurveyFirstPage';
import SurveySecondPage from './pages/surveySecondPage/SurveySecondPage';
import SurveyThirdPage from './pages/surveyThirdPage/SurveyThirdPage';
import SurveyCompletePage from './pages/surveyCompletePage/SurveyCompletePage';

import * as StorageConstants from './constants/StorageConstants';

import './App.css';

export default class App extends React.Component {
	constructor(props) {
		super(props);

        /* User Context */

        let user;
        let userValue = sessionStorage.getItem(StorageConstants.USER_KEY);
        if (userValue != null) {
            user = JSON.parse(userValue);
        } else {
            user = {
                userId: 0,
                username: "",
                password: "",
                token: ""
            };
        }
        sessionStorage.setItem(StorageConstants.USER_KEY, JSON.stringify(user));

        this.setUserValues = (set) => {
            let user = this.state.user;
            set.forEach(item => {
                user[item.key] = item.value;
            });
            sessionStorage.setItem(StorageConstants.USER_KEY, JSON.stringify(user));
            this.setState({user});
        };

        /* Survey Context */

        let survey;
        let surveyValue = sessionStorage.getItem(StorageConstants.SURVEY_KEY);
        if (surveyValue !== null) {
            survey = JSON.parse(surveyValue);
        } else {
            survey = {
                id: 0,
                lga: "",
                evacuationReason: "",
                warningLevel: "",
                shelterOnEvacuation: "",
                shelterOnEvacuationOther: "",
                shelterSatisfaction: "",
                shelterSatisfactionReason: "",
                shelterRepeat: "",
                shelterRepeatReason: "",
                evacueeHost: "",
                howEvacueeFound: "",
                hostRepeat: "",
                hostRepeatReason: "",
                contactLater: "",
                contactEmail: "",
                contactPhone: "",
            };
        }
        sessionStorage.setItem(StorageConstants.SURVEY_KEY, JSON.stringify(survey));

        this.setSurveyValues = (set) => {
            let survey = this.state.survey;
            set.forEach(item => {
                survey[item.key] = item.value;
            });
            sessionStorage.setItem(StorageConstants.SURVEY_KEY, JSON.stringify(survey));
            this.setState({survey});
        };

        /* Owner Context */

        let owner;
        let ownerValue = sessionStorage.getItem(StorageConstants.OWNER_KEY);
        if (ownerValue != null) {
            owner = JSON.parse(ownerValue);
        } else {
            owner = {
                id: 0,
                userId: 0,
                name: "",
                profileImage: "",
                preferredContactType: "",
                contactNumberOrAccount: "",
            };
        }

        this.setOwnerValues = (set) => {
            let owner = this.state.owner;
            set.forEach(item => {
                owner[item.key] = item.value;
            });
            sessionStorage.setItem(StorageConstants.OWNER_KEY, JSON.stringify(owner));
            this.setState({owner});
        };

        /* Accommodation Context */

        let accommodation;
        let accommodationValue = sessionStorage.getItem(StorageConstants.ACCOMMODATION_KEY);
        if (accommodationValue !== null) {
            accommodation = JSON.parse(accommodationValue);
        } else {
            accommodation = {
                id: 0,
                addressStreet1: "",
                addressStreet2: "",
                addressLocality: "",
                addressState: "",
                addressPostcode: "",
                addressLocation: null,
                paymentConditions: "",
                accommodationType: "",
                accommodationTypeOther: "",
                ownerManagerUsername: "",
                priorListing: "",
                activeListing: "",
                listingURL: "",
                straListing: "",
                straPropertyId: "",
                offeringTime: 0,
                offeringPeriod: "",
                maximumOccupancy: 0,
                amenitiesRequired: "",
                furnished: "",
                noOfBedrooms: "",
                noOfBathrooms: "",
                kitchenFacilities: "",
                childFriendly: "",
                petFriendly: "",
                acceptingLivestock: "",
                mobilityAccessible: "",
                entryStairs: "",
                flooring: "",
                entranceGroundCover: "",
                allocatedParking: "",
                bedroomRailing: "",
                showerRailing: "",
                toiletRailing: "",
                hallwayRailing: "",
                kitchenRailing: "",
                livingRailing: "",
                externalRailing: "",
                active: false,
                createdBy: "",
                updatedBy: ""
            };
        }
        sessionStorage.setItem(StorageConstants.ACCOMMODATION_KEY, JSON.stringify(accommodation));

        this.setAccommodationValues = (set) => {
            let accommodation = this.state.accommodation;
            set.forEach(item => {
                accommodation[item.key] = item.value;
            });
            sessionStorage.setItem(StorageConstants.ACCOMMODATION_KEY, JSON.stringify(accommodation));
            this.setState({accommodation});
        };

        /* Accommodation Amenities Context */

        let accomAmenities;
        let accomAmenitiesValue = sessionStorage.getItem(StorageConstants.ACCOM_AMENITIES_KEY);
        if (accomAmenitiesValue != null) {
            accomAmenities = JSON.parse(accomAmenitiesValue);
        } else {
            accomAmenities = [];
        }
        sessionStorage.setItem(StorageConstants.ACCOM_AMENITIES_KEY, JSON.stringify(accomAmenities));

        this.setAccomAmenities = (value) => {
            let accomAmenities = value;
            sessionStorage.setItem(StorageConstants.ACCOM_AMENITIES_KEY, JSON.stringify(accomAmenities));
            this.setState({accomAmenities});
        };

        /* Rooms Context */

        let rooms;
        let roomsValue = sessionStorage.getItem(StorageConstants.ACCOM_AMENITIES_KEY);
        if (roomsValue != null) {
            rooms = JSON.parse(roomsValue);
        } else {
            rooms = [];
        }
        sessionStorage.setItem(StorageConstants.ROOMS_KEY, JSON.stringify(rooms));

        this.setRooms = (value) => {
            let rooms = value;
            sessionStorage.setItem(StorageConstants.ROOMS_KEY, JSON.stringify(rooms));
            this.setState({rooms});
        };

        /* Accommodation Applicant Context */

        let accomApplicant;
        let accomApplicantValue = sessionStorage.getItem(StorageConstants.ACCOM_APPLICANT_KEY);
        if (accomApplicantValue != null) {
            accomApplicant = JSON.parse(accomApplicantValue);
        } else {
            accomApplicant = {
                id: 0,
                userId: 0,
                name: "",
            };
        }
        sessionStorage.setItem(StorageConstants.ACCOM_APPLICANT_KEY, JSON.stringify(accomApplicant));

        this.setAccomApplicantValues = (set) => {
            let accomApplicant = this.state.accomApplicant;
            set.forEach(item => {
                accomApplicant[item.key] = item.value;
            });
            sessionStorage.setItem(StorageConstants.ACCOM_APPLICANT_KEY, JSON.stringify(accomApplicant));
            this.setState({accomApplicant});
        };

        /* Accommodation Application Context */

        let accomApplication;
        let accomApplicationValue = sessionStorage.getItem(StorageConstants.ACCOM_APPLICATION_KEY);
        if (accomApplicationValue !== null) {
            accomApplication = JSON.parse(accomApplicationValue);
        } else {
            accomApplication = {
                id: 0,
                applicantId: 0,
                previousAddressStreet1: "",
                previousAddressStreet2: "",
                previousAddressLocality: "",
                previousAddressState: "",
                previousAddressPostcode: "",
                previousAddressLocation: null,
                applicationReason: "",
                applicationReasonOther: "",
                noOfAdults: 0,
                noOfOlderChildren: 0,
                noOfChildren: 0,
                noOfInfants: 0,
                noOfPets: 0,
                applicantTypeOther: "",
                mobilityIssues: false,
                englishDifficulty: false,
                domesticViolenceExperience: false,
                vulnerabilitiesOther: "",
                dcjHousingResident: false,
                scchClient: false,
                agedCareResident: false,
                housingPreferencesOther: "",
                paymentPerNight: 0,
                applicationStatus: "",
                accomPlacementID: 0,
                dateApplicationSubmitted: null,
                datePlacementStarted: null,
                datePlacementEnded: null,
                notes: "",
                createdBy: "",
                updatedBy: ""
            };
        }
        sessionStorage.setItem(StorageConstants.ACCOM_APPLICATION_KEY, JSON.stringify(accomApplication));

        this.setAccomApplicationValues = (set) => {
            let accomApplication = this.state.accomApplication;
            set.forEach(item => {
                accomApplication[item.key] = item.value;
            });
            sessionStorage.setItem(StorageConstants.ACCOM_APPLICATION_KEY, JSON.stringify(accomApplication));
            this.setState({accomApplication});
        };

        /* Accommodation Preferences Context */

        let accomPreferences;
        let accomPreferencesValue = sessionStorage.getItem(StorageConstants.ACCOM_PREFERENCES_KEY);
        if (accomPreferencesValue != null) {
            accomPreferences = JSON.parse(accomPreferencesValue);
        } else {
            accomPreferences = [];
        }
        sessionStorage.setItem(StorageConstants.ACCOM_PREFERENCES_KEY, JSON.stringify(accomPreferences));

        this.setAccomPreferences = (value) => {
            let accomPreferences = value;
            sessionStorage.setItem(StorageConstants.ACCOM_PREFERENCES_KEY, JSON.stringify(accomPreferences));
            this.setState({accomPreferences});
        };

        /* Applicant Contacts Context */

        let applicantContacts;
        let applicantContactsValue = sessionStorage.getItem(StorageConstants.APPLICANT_CONTACTS_KEY);
        if (applicantContactsValue != null) {
            applicantContacts = JSON.parse(applicantContactsValue);
        } else {
            applicantContacts = [];
        }
        sessionStorage.setItem(StorageConstants.APPLICANT_CONTACTS_KEY, JSON.stringify(applicantContacts));

        this.setApplicantContacts = (value) => {
            let applicantContacts = value;
            sessionStorage.setItem(StorageConstants.APPLICANT_CONTACTS_KEY, JSON.stringify(applicantContacts));
            this.setState({applicantContacts});
        };

        /* Emergency Contacts Context */

        let emergencyContacts;
        let emergencyContactsValue = sessionStorage.getItem(StorageConstants.EMERGENCY_CONTACTS_KEY);
        if (emergencyContactsValue != null) {
            emergencyContacts = JSON.parse(emergencyContactsValue);
        } else {
            emergencyContacts = [];
        }
        sessionStorage.setItem(StorageConstants.EMERGENCY_CONTACTS_KEY, JSON.stringify(emergencyContacts));

        this.setEmergencyContacts = (value) => {
            let emergencyContacts = value;
            sessionStorage.setItem(StorageConstants.EMERGENCY_CONTACTS_KEY, JSON.stringify(emergencyContacts));
            this.setState({emergencyContacts});
        };

        this.state = {
            user: user,
            survey: survey,
            owner: owner,
            accommodation: accommodation,
            accomAmenities: accomAmenities,
            rooms: rooms,
            accomApplicant: accomApplicant,
            accomApplication: accomApplication,
            accomPreferences: accomPreferences,
            applicantContacts: applicantContacts,
            emergencyContacts: emergencyContacts,
            setUserValues: this.setUserValues,
            setSurveyValues: this.setSurveyValues,
            setOwnerValues: this.setOwnerValues,
            setAccommodationValues: this.setAccommodationValues,
            setAccomAmenities: this.setAccomAmenities,
            setRooms: this.setRooms,
            setAccomApplicantValues: this.setAccomApplicantValues,
            setAccomApplicationValues: this.setAccomApplicationValues,
            setAccomPreferences: this.setAccomPreferences,
            setApplicantContacts: this.setApplicantContacts,
            setEmergencyContacts: this.setEmergencyContacts
        };
    }

    getSubdomain() {
        let urlSplit = window.location.href.split('/').filter(part => part.length > 0 && !part.startsWith('http'));
        return urlSplit[0].split('.')[0];
    }

    render() {
        let survey = this.state.survey;
        let subdomain = this.getSubdomain();
        return (
        <GlobalContext.Provider value={this.state}>
            <div className="app-container">
            <BrowserRouter>
                <Routes>
                    {subdomain === 'survey' && survey.id === 0 &&
                        <Route path="/" element={<SurveyFrontPage/>}/>
                    }
                    {subdomain === 'survey' && survey.id !== 0 &&
                        <Route path="/" element={<SurveyCompletePage/>}/>
                    }
                    {subdomain !== 'survey' &&
                        <Route path="/" element={<FrontPage/>}/>
                    }
                    <Route path="/offering/register" element={<OfferRegistrationPage/>}/>
                    <Route path="/offering/login" element={<OfferLoginPage/>}/>
                    <Route path="/offering/1" element={<OfferBasicDetailsPage/>}/>
                    <Route path="/offering/2" element={<OfferTypeListingPage/>}/>
                    <Route path="/offering/3" element={<OfferPeriodCapacityPage/>}/>
                    <Route path="/offering/4" element={<OfferAccessibilityPage/>}/>
                    <Route path="/offering/5" element={<OfferAmenityDetailsPage/>}/>
                    <Route path="/offering/6" element={<OfferRoomsPage/>}/>
                    <Route path="/offering/7" element={<OfferCompletePage/>}/>
                    <Route path="/accapp/register" element={<AccAppRegistrationPage/>}/>
                    <Route path="/accapp/login" element={<AccAppLoginPage/>}/>
                    <Route path="/accapp/1" element={<AccAppBasicDetailsPage/>}/>
                    <Route path="/accapp/2" element={<AccAppContactsPage/>}/>
                    <Route path="/accapp/3" element={<AccAppNumbersNeedsPage/>}/>        
                    <Route path="/accapp/4" element={<AccAppTypeFeePage/>}/> 
                    <Route path="/accapp/5" element={<AccAppCompletePage/>}/> 

                    {survey.id === 0 && 
                        <Route path="/survey/1" element={<SurveyFirstPage/>}/>
                    }       
                    {survey.id !== 0 && 
                        <Route path="/survey/1" element={<SurveyCompletePage/>}/>
                    }             
                    {survey.id === 0 && 
                        <Route path="/survey/2" element={<SurveySecondPage/>}/>
                    }       
                    {survey.id !== 0 && 
                        <Route path="/survey/2" element={<SurveyCompletePage/>}/>
                    }             
                    {survey.id === 0 && 
                        <Route path="/survey/3" element={<SurveyThirdPage/>}/>
                    }       
                    {survey.id !== 0 && 
                        <Route path="/survey/3" element={<SurveyCompletePage/>}/>
                    }
                    <Route path="/survey/complete" element={<SurveyCompletePage/>}/>             
                </Routes>
            </BrowserRouter>
            </div>
        </GlobalContext.Provider>
        );
    }
}
